import React, {useState, useRef, useEffect} from 'react';

// window.onerror = function(message, url, line, col, errorObj) {
//   //console.log(`${message}\n${url}, ${line}:${col}`);
//   //console.log(errorObj);
// };

export const CropImagePlacePhoto = (props) => {
    const initialized = useRef(false);

    const {postData, postId, onSetModalBox2} = props;

    const canvasRef = useRef(null);
    const imgRef = useRef(null);    
    
    const [imgShow, setImgShow] = useState(true);
    const [imgShowCanvas, setImgShowCanvas] = useState(false); 

    function imageToCanvasDataURL(image, callback) {

      //console.log("imageToCanvasDataURL--------------------------44444444444444444444444444444444444444");

      var canvasImage = canvasRef.current;  

      var crop = calculateWidthHeight(image);
      if(crop == null)
      {
        return;
      }

      const canvas = canvasImage;

      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
          throw new Error('No 2d context');
      }         

      const pixelRatio = window.devicePixelRatio;

      ctx.imageSmoothingQuality = 'high';
      // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
      try {
        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,           
          0,
          0,
          image.width,
          image.height 
        );
      } catch (err) {
        console.error(`drawImage-Error: ${err}`);
      }        

      try {
        callback();            
      } catch (err) {
        console.error(`dataURL-Error: ${err}`);
      }
    } 

    const calculateWidthHeight = (image) => {

      let scaleMedia = image.naturalWidth / image.naturalHeight;
      let scaleDevice = image.width / image.height;   
      
      //console.log('scaleMedia-',scaleMedia,'scaleDevice-',scaleDevice);

      var mediaWidth = image.naturalWidth;
      var mediaHeight = image.naturalHeight;      
 

      var cropRect = null;
      var cropWidth = null;
      // var cropRect = {
      //   'x': 0,
      //   'y': 0,
      //   'width': mediaWidth,
      //   'height': mediaHeight
      // };
      
      //if(scaleDevice == 1)
      {
        cropWidth = Math.min(mediaWidth, mediaHeight);
        cropRect = {
          'x': (mediaWidth - cropWidth) / 2.0,
          'y': (mediaHeight - cropWidth) / 2.0,
          'width': cropWidth,
          'height': cropWidth
        };
      }

      // case 1. check landscape without face 
      if(
          scaleMedia != 1 // need to scale as media is not 1:1
          && scaleDevice != 1 // lanscape rectangle
      )
      {
        return cropRect;
      }
      // case 2. check 1:1 without face
      else if(
        scaleMedia != 1 // need to scale as media is not 1:1
        && scaleDevice == 1 // 1:1 square
      )
      {
        return cropRect;
      }      
   
      return null;
    };


    const onLoadImage = () => {
      //console.log('onLoadImage');
      cropImageNow();
    };
    
    const onLoadImageCanvas = () => {
      //console.log('onLoadImageCanvas'); 
    };

    const onErrorImage = () => {
      console.error('onErrorImage');

      var image = imgRef.current;
      //console.log(image, image.closest(".Place_Photo_Box"), image.closest(".Place_Photo_Box").closest(".flex-parent"));

      image.closest(".Place_Photo_Box").style.width = 0;
      // Map_Photo_Box
      image.closest(".Place_Photo_Box").closest(".flex-parent").querySelector(".Map_Photo_Box").style.width = "100%";

      image.remove();
    };
    
    const onErrorImageCanvas = () => {
      console.error('onErrorImage'); 
    };    

    const cropImageNow = () => {

      var image = imgRef.current;
       
      imageToCanvasDataURL(image, ()=>{
        setImgShow(false);
        setImgShowCanvas(true);
      });

    };
    

  useEffect(() => {

    if (!initialized.current) 
    {
      initialized.current = true; 
      initialized.counter = 1;   
      
      var image = imgRef.current;
    
      image.src = postData.Map_Place_Thumb_Photo;
    }
    else
    {
      initialized.counter += 1;
    }
    //console.log(imgRef.current);
    //console.error("CropImage-Already initialized-counter:",initialized.counter);

    // Anything in here is fired on component mount.

    return () => {
      // Anything in here is fired on component unmount.

      //console.log("-------------CropImagePlacePhoto---------------11111111111------");

    }    
  }, []);
    

  return (<>    
    {<img ref={imgRef} className='Place_Photo_Img'  style={{ display: imgShow ? "block" : "none" }} onLoad={onLoadImage} onError={onErrorImage} onClick={() => onSetModalBox2({showModal:true,slideIndex:0,modalPost:postData})} />}    
    {<canvas ref={canvasRef} className='Place_Photo_Img' style={{ display: imgShowCanvas ? "block" : "none" }} onClick={() => onSetModalBox2({showModal:true,slideIndex:0,modalPost:postData})} />} 
  </>);

}

export default CropImagePlacePhoto;