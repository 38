import React, { Component,useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from 'moment';
import { decode, decodeEntity, encode } from 'html-entities';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import user_default from "./Hexagon.jpg"; // relative path to image 
import withRouter from './withRouter';
import sayimage from './app-images/comment.png' // relative path to image 
import shareimage from './app-images/spread.png' // relative path to image 
import playimg from './app-images/playbut.png';
import trvelpic from './app-images/travelimg.png';
import statuspic1 from './app-images/statuspic.png';
import statuspic2 from './app-images/image3.png';
import  DOMPurify from 'dompurify';
import bucket_icon1 from './app-images/doubletick.png';
import bucket_icon2 from './app-images/tickimage.png';
import bucket_icon3 from './app-images/plusicon-white.png';
import blank from './app-images/blank.png';
import levelicon0 from './app-images/cobalt.png';
import levelicon1 from './app-images/silver.png';
import levelicon2 from './app-images/gold.png';
import levelicon3 from './app-images/platinum.png';
import levelicon4 from './app-images/sprectra.png';
import levelicon5 from './app-images/cobalt.png';
import parse from 'html-react-parser';
import loader from './app-images/loader.gif';
import travel_runner from './app-images/travelmn.png';
const reactStringReplace = require('react-string-replace');
//import  { Redirect } from 'react-router-dom';
import Home from "./home";
import * as constants from '../constants';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
const kudurl = constants.ADMIN_BASE_URL + 'images/kudos_gray.png';
const PRE_URL = constants.BUCKET_URL;
const PREFIX_URL = PRE_URL;
const image_profile_url = constants.BUCKET_URL + "media/users/s138tfgjas58qqu2ll7yega061662965046437/profilepics/pic_profile/1667460827681.jpg";
const mapimg = "";
const img_pic = "";
const default_url =constants.BUCKET_URL + "media/users/9qh36fgjas58qqt62lfcgmquh1679052710249/posts/170437640077343603.jpg";
const plusicon ="./app-images/"
const url = "https://jsonplaceholder.typicode.com/users";
const styles = {
    disabledButton: {
   //   cursor: 'not-allowed',
      margin: 5,    
      borderRadius: "8px",
      border: "none",
      
  },
  enabledButton: {
      margin: 10,
      borderRadius: "8px",
      border: "none",
      boxShadow: "0px 0px 10px 0px grey",
  },
};


class MyVideoSlider extends React.Component {
  constructor() {
    super();
    this.images = [];
    this.state = {
      isError:false,
      redirect: null,
      showtext: false,
      postype: 0,
      totaldata:{},
      imagedata: {},
      mediapath: [],
      previewdata:[],
      showIndex: false,
      showBullets: true,
      infinite: false,
      showThumbnails: false,
      showFullscreenButton: false,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: true,
      showNav: false,
      isRTL: false,
      slideDuration: 600,
      slideInterval: 3000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
      video_data:false,
      isDisplay: true,
      setButtonDisabled:true,
      isButtonDisabled:false,
      videostatus:false,
      travelmonth:0,
      travelday:0,
      videoUrl:null,
      indexvalue:0,
      buttonPressed:false,
      widthvalue:(window.innerWidth > 760) ? 400 : screen.width,
      caption_text:null,
    };
  }

 _checkPhone(phoneno) {
    var phone = phoneno;
    var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
        if(phone?.match(phoneNum)) {
            return true;
        }
        else {
           return false;
        }
    }

    _validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

  _urlify(text) {    
         var tt = decodeURIComponent(text);
         const delimiter = '/((?:https?:\/\/)?/(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi';
         const pokemonIndex = tt?.split(/[\n,\s+]/).map(word => {
          word = word.toLowerCase();  
          if(this._checkPhone(word)==true){ 
            return  encode("<a href='tel:"+word+"'>"+word+"</a>");
           }
          if(this._validateEmail(word)){
            return  encode("<a href='mailto:"+word+"' >"+word+"</a>");

          }
          const httpcheck = word.startsWith("http");
          const match = word.startsWith("www.");          
          if (match) { 
           if(httpcheck){
            return  encode("<a href='"+word+"' target='_blank' >"+word+"</a>");
           }else{
            return  encode("<a href='https//"+word+"' target='_blank' >"+word+"</a>");
           } 
          }else{
            if(httpcheck){
              return  encode("<a href='"+word+"' target='_blank' >"+word+"</a>");
            }else{
              return word;
            }
          }          
        }).join(" ");
  return pokemonIndex;
}

//**********************************************/

  _butonHtml(valueIndex,totalindex){
    let bulletHtml="";
       for(let i=0;i<totalindex;i++)
        {
          let j=1+i;
          bulletHtml +='<button type="button" class="image-gallery-bullet" aria-pressed="false" aria-label='
          +"Go to Slide "+j+'></button>';
        }       
       return bulletHtml;
}

  _onReady(event) {
    const player = this.state.player;
    player.push(event.target);
    this.setState({ player: player });
  }

  _openModal() {
    this.setState({ open: true });
  }

  _closeModal() {
    this.setState({ open: false, player: [] });
  }
  /***********************month difference***********************************/
  _getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  
  
  /**************************************************/
  async _fetchInfo() { 
    return await axios.post(constants.API_BASE_URL+'postDetails/',
      {
        User_Private_Key: "2o8kAIkX87S0l3jf36758119009829451701065156525",
        User_TokenKey: "b0hykvpjas58qqldflpgi9qzo1701065151203",
        Post_ID: this.props.params.id
      }).then((res) => {
        return res.data;
      }).catch(() => {
       // console.log("fail");
        return { isError:true };

      });
  }

  componentDidMount() {   
    var app_data =""; 
    this.promise = this._fetchInfo();
    this.images = [];
    setTimeout(() => {
      this.promise.then(
        (result) => {
          var app_data ="";
          this.imgdata = result;
          this.setState({totaldata:this.imgdata});
          if(this.imgdata.message!="posts details are" || this.promise.isError==true)
          {
            <Routes>
            <Route
                exact
                path="/"
                element={<Home />}
            />
        </Routes>
          }else{
            if(this.imgdata.data[0].preview_metadata!="")    app_data  = this.imgdata.data[0].preview_metadata;          
                this.setState({ imagedata: this.imgdata.data[0] });
                this.setState({ mediapath: this.imgdata.data[0].Media_FilePath });
                this.setState({ postype: this.imgdata.data[0].Post_Type });
              if(app_data.length>0) this.setState({previewdata:JSON.parse(app_data)});
              }
         } 
      );
    }, 100);
  
  }

  _onImageClick(event) {
   const divs = document.querySelectorAll('.image-gallery-bullet');
    let index= this._imageGallery.getCurrentIndex();
    let dataCount = this._imageGallery.length;
   if(index==0){
        var divdata = document.getElementsByClassName('image-gallery-bullets-container');
         divdata.innerHTML = this._butonHtml(index,dataCount);         
     }
     let element = document.getElementsByClassName('image-gallery-bullet');
    for(let n=0;n<element.length;n++){
        if(n==index){
         element[n].className="image-gallery-bullet active",  
         element[n]['aria-pressed']=true        
       }else{
         element[n].className="image-gallery-bullet",
         element[n]['aria-pressed']=false
       }
    }
  }

  _slideToIndex(index){
    this.state.videostatus=true;
    console.debug('loaded image',index);
    
  }
  _onImageLoad(event) {
    this.state.videostatus=true;
    let indexdata = this._imageGallery.getCurrentIndex();
    this._onSlide(indexdata,true);
   
  }
 
  _onSlide(index,val) {    
       this.state.indexvalue = index;    
     //console.debug('slid to index datata',index); 
     if(val==null){      
      this.state.videostatus=false;
      }
     if(val==true){ this.state.videostatus=val; 
    this.state.videoUrl='';
    }
    let elementNew =[];
     var element = document.getElementsByClassName("image-gallery-bullet"); 
     for(let n=0;n<element.length;n++){
      // element[n]['aria-pressed']="true"   
    //   console.log(element[n].className,element[n]['aria-pressed']); 
       elementNew.push(element[n]);
     }   
      element = elementNew;
     for(let n=0;n<element.length;n++){
      //console.log(element[n]);
      }  
     
    for(let n=0;n<element.length;n++){
         if(n!=index){
          element[n].className="image-gallery-bullet",  
          element[n]['aria-pressed']="false"        
        }else{
          element[n].className="image-gallery-bullet active"
          element[n]['aria-pressed']=true
        }
     }     
    //console.log("step2",element);   
    }

  _onPause(index) {
    //this.state.currentIndex=index;
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
    
  }
  
  _handleInputChange(state, event) {    
    if (event.target.value > 0) {
      this.setState({ [state]: event.target.value });
    }
    }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  
//********************image check*************************/
_checkIfImageExists(url, callback) {
    const img = new Image();
  img.src = url;
  
  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    
    img.onerror = () => {
      callback(false);
    };
  }
}


// USAGE

//********************image check**************************/


  _resetVideo(url) {
    
    this.setState({ showVideo: {} });
    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: false });
    }
    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: false });
    }
  }
  
  _handleClick = (event) => {
   // console.log(event.target.innerText);    // Click Me
  //  console.log(event.target.tagName);      // BUTTON
}

  _toggleShowVideo(url) {     
    //****************************/        
     if(this.state.currentIndex==null) this.state.currentIndex=0;
     let indextext= this.state.currentIndex +1;
    let stringlabel = 'button[aria-label="Go to Slide '+indextext+'"]';    
  
    let indexvalue = this._imageGallery.getCurrentIndex();    
   if(indexvalue==null) indexvalue=0;
      // console.log(indexvalue);
      this._onSlide(indexvalue,true);
    //****************************/
     this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
     this.state.videoUrl = url;
     this.setState({ showVideo: this.state.showVideo });
   //****************************/

  }

_playRandom(url){    
 // this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
}






  _renderVideoFrame(item) {
    this.video_data = this._checkVideo(item.embedUrl);
    return (            
      <div className="justify-content-center">        
        {item.embedUrl!=null ? (
          <div className="video-wrapper ">
             {/* <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a> */}
            <iframe            
               width="400px"
               height="400px"
               src={item.embedUrl+"?autopause=true"}
               frameBorder="0"
               allowFullScreen
               className="slider-with-video"
            ></iframe>
          </div>
        ) : (           
            <a className="slider-img">
            <div className="play-button" >
            </div>
            {item.embedUrl?(<img src={playimg} className="play_icon" width="60px" height="60px" style={{maxWidth:'100%'}} />            
           ):(<div></div>)}
            <img onClick={this._playRandom(item.embedUrl)}  width="400px"
               height="400px" style={{maxWidth:'100%'}}  src={item.original} />            
            </a>          
        )}
       </div> 
      
    );
  }
  
  _renderVideo_media(item) {
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="400px"
              height="400px"
              src={item.embedUrl}
              frameBorder="2"
              allowFullScreen
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            {item.embedUrl?(<img src={playimg} className="play_icon" width="60px" height="60px" style={{maxWidth:'100%'}} />            
           ):(<div></div>)}
            {this.imgdata.data[0].Map_Place_Thumb_Photo != "" ? (<img className="image-image-media-place" src={item.original} />) :
              (<img className="image-image-media" src={item.original} />)}
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: '0', left: 'initial' }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}<div><img src={this.state.imagedata.Wall_Post_Map_Url} className="image-image-travel-place" ></img></div>
      </div>);

  }
  _renderVideo(item) {
    if (this.state.imagedata.Post_type != 3) {
      return (
        <div>
          {this.state.showVideo[item.embedUrl] ? (
            <div className="video-wrapper">
              <a
                className="close-video"
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              ></a>
              <iframe
                width="auto"
                height="auto"
                src={item.embedUrl}
                frameBorder="2"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                  <div className="play-button"></div>
                  {item.embedUrl?(<img src={playimg} className="play_icon" width="60px" height="60px" style={{maxWidth:'100%'}} />            
           ):(<div></div>)}
              <img className="image_slide_map_explurge1" src={item.original} />
              {item.description && (
                <span className="image-gallery-description"
                  style={{ right: '0', left: 'initial' }}
                >
                  {item.description}
                </span>
              )}
            </a>
          )}</div>

      );
    }
  }
_renderVideo_spread(item) {
    if (this.state.imagedata.Post_type != 3) {
      return (
        <div style={{width:400,height:400}}>
          {this.state.showVideo[item.embedUrl] ? (
            <div className="video-wrapper">
              <a
                className="close-video"
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              ></a>
              <iframe
                width="auto"
                height="auto"
                src={item.embedUrl}
                frameBorder="2"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                  <div className="play-button"></div>
                  {item.embedUrl?(<img src={playimg} className="play_icon" width="60px" height="60px" style={{maxWidth:'100%'}} />            
           ):(<div></div>)}
              <img className="image_slide_map_explurge1" src={item.original} />
              {item.description && (
                <span className="image-gallery-description"
                  style={{ right: '0', left: 'initial' }}
                >
                  {item.description}
                </span>
              )}
            </a>
          )}</div>

      );
    }
  }

  _click_video(url){ 
      
  this.state.videostatus=true;

    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    } 
      }

  
 
_checkVideo(file){

var str = file; 
 
var re = /.mp4/
 
// use of String search() Method
if(str!=null){
  if (str.search(re) == -1 ) {
        return false;
  } else { 
     // console.log("Found"); 
      return true;
  }
}
}

  _getStaticImages() {
    let v_image = "";
    let media = "";
    let collectionimages = [];
    if (this.state.postype == "0") {
      if (this.state.mediapath.length>0) {
        this.state.mediapath.forEach((e) => {
          collectionimages.push({
            thumbnail:  e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            original:   e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            embedUrl: e.Wall_Media_Path ? e.Wall_Media_Path : '',
            renderItem: this._renderVideo_travel.bind(this),
          });
        });
        return collectionimages;
      }
    }
    if (this.state.postype == "1") {
        this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,
          renderItem: this._renderVideo_travel.bind(this),
        });       
      });
       
      return collectionimages;
    }
    if (this.state.postype == "2") {

      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
          collectionimages.push({
            thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            embedUrl: blank_data!="" ? blank_data : null,
            renderItem: this._renderVideo_travel.bind(this),
          });
      });
      return collectionimages;

    }
     

    if (this.state.postype == "3") {
      if (this.state.mediapath.length > 0) {
        this.state.mediapath.forEach((e) => {
          if(this._checkVideo(e.Wall_Media_Path)){
            var blank_data = e.Wall_Media_Path;
          }else{
             var blank_data = null;
          }
          collectionimages.push({
            thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
            embedUrl: blank_data!="" ? blank_data : null,
            renderItem: this._renderVideo_travel.bind(this),
          });
        });
      }
      return collectionimages;
    }

    if (this.state.postype == "4") {
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,
          renderItem: this._renderVideo_travel.bind(this),
        });
      });
      return collectionimages;
    }
    if (this.state.postype == "5") {
      
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,
          renderItem: this._renderVideo_travel.bind(this),
        });
      });
      return collectionimages;
    }
    if (this.state.postype == "6") {
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl:blank_data!="" ? blank_data : null,
          renderItem: this._renderVideo_travel.bind(this),
        });
      });
      return collectionimages;
    }
    if (this.state.postype == "7") {
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,
          backgroundColor:'#c1c1c1',
          renderItem: this._renderVideo_profile.bind(this),
        });
      });
      return collectionimages;
    }
    if (this.state.postype == "8") {
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,
          renderItem: this._renderVideo_travel.bind(this),
        });
      });
      return collectionimages;
    }
    if (this.state.postype == "9") {
      this.state.mediapath.forEach((e) => {
        if(this._checkVideo(e.Wall_Media_Path)){
          var blank_data = e.Wall_Media_Path;
        }else{
           var blank_data = null;
        }
        collectionimages.push({
          thumbnail: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          original: e.GCS_Video_Placeholder ? e.GCS_Video_Placeholder : e.Wall_Media_Path,
          embedUrl: blank_data!="" ? blank_data : null,          
          renderItem: this._renderVideo_travel_9.bind(this),
        });
      });
      return collectionimages;
    }
    return collectionimages;

  }

  _renderVideo_travel(item) { 
        const imageUrl =item.original;
        this.state.widthvalue = (window.innerWidth > 760) ? window.innerWidth/3 : screen.width;
        this.state.widthvalue = this.state.widthvalue;
     
     this.video_data = this._checkVideo(item.embedUrl);
     const imageUrlRegex = /\.(jpeg|jpg|gif|png|webp)$/i;
    const isValidImageUrl = imageUrlRegex.test(imageUrl);     
    if(this.state.widthvalue>500) this.state.widthvalue=400;
  
   if(isValidImageUrl==true && item.embedUrl!=null &&  this.video_data==true){    
    if(this.state.videostatus==true && item.embedUrl!="" && this.video_data==true){
    let indexdata = this.state.indexvalue;
    
    var element = document.getElementsByClassName("image-gallery-bullet"); 
    for(let n=0;n<element.length;n++){
       element[n].className="image-gallery-bullet",
       element[n]['aria-pressed']=false
    }    
    for(let n=0;n<element.length;n++){
         if(n==indexdata){
          element[n].className="image-gallery-bullet active",
          element[n]['aria-pressed']=true 
        }else{
          element[n].className="image-gallery-bullet"
          element[n]['aria-pressed']=false 
        }
    }  
   if(indexdata==0){
    for(let n=0;n<element.length;n++){
      if(n==indexdata){
       element[n].className="image-gallery-bullet active",
       element[n]['aria-pressed']=true  
     }
    } 
}


  for(let n=0;n<element.length;n++){
    if(n==indexdata && element[n].className=="image-gallery-bullet active"){
       element[n]['aria-pressed']='true' 
     }
     if(n==indexdata && element[n].className=="image-gallery-bullet"){
      element[n].className="image-gallery-bullet active"
      element[n]['aria-pressed']='true' 
    }
  } 
 // console.log(this.state.videoUrl);

     let data =[];
     data[this.state.videoUrl]=this.state.videoUrl;
     //  console.log(this.state.videoUrl);

    if(data[this.state.videoUrl]){      
      
    return ( 
        <div className="container">
           <div className="video-wrapper-travel">
           <video id="vid" controls autoplay="true" width={this.state.widthvalue}   height={this.state.widthvalue} >
            <source src={this.state.videoUrl} type="video/mp4"></source>
            </video>
            </div>
            </div>);}
     }
     else{   
    if(this.state.videostatus==false && item.embedUrl!=""){  
      this.state.videoUrl="";
      this.state.videoUrl = item.embedUrl;
    return ( 
     <div className="container"> 
          <div className="play-button"></div>
          {item.embedUrl?(<img src={playimg} onClick={this._toggleShowVideo.bind(this,item.embedUrl)} className="play_icon" width={60} height={60} />            
          ):(<div></div>)}
        { this.state.imagedata.Map_Place_Thumb_Photo != "" ? (<a onClick={this._toggleShowVideo.bind(this,item.embedUrl)}><img  src={item.original} style={{ width:this.state.widthvalue,height:this.state.widthvalue }} /></a>
          ) : (<a onClick={this._toggleShowVideo.bind(this,item.embedUrl)}><img  src={item.original}  style={{ width: this.state.widthvalue,height:this.state.widthvalue }} /></a>)}
    </div>
       )
    }else{
      return(     <div className="container"> 
      <div className="notouch"><img  src={item.original} style={{ width:'100%',height:350}} /></div></div>)
    }
      }
    }else{
      if(this.state.widthvalue>500) this.state.widthvalue=400;
        return(<div className="container"> 
        <div className="notouch"><img  src={item.original} style={{ width:'100%',height:350 }}  /></div></div>)
    }
}
_renderVideo_travel_9(item) {
  const imageUrl =item.original;
        this.state.widthvalue = (window.innerWidth > 760) ? window.innerWidth/3 : screen.width;
        this.state.widthvalue = this.state.widthvalue;
  let image_check= this._checkIfImageExists(item.original,(exists) => {
     if (exists) {
       return true;
     } else {
       return false;
     }
   });
  
   if(image_check){
   return (      
     <div className="slide-image-data">
       {this.state.showVideo[item.embedUrl] ? (
         <div className="video-wrapper-travel">
           <a className="close-video" onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
           ></a>
           <iframe
             width={this.state.widthvalue}
             height={this.state.widthvalue}
             src={item.embedUrl}
             frameBorder="0"
             allowFullScreen
           ></iframe>
         </div>
       ) : (
         <div className="slide-image-data">
           <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
             <div className="play-button"></div>
             {this.state.imagedata.Map_Place_Thumb_Photo != "" ? (<img  src={item.original} className="linkclass" style={{ width:this.state.widthvalue,height:this.state.widthvalue }} />
) : (<img  src={item.original} className="linkclass" style={{ width: this.state.widthvalue,height:this.state.widthvalue }} />)}
             {item.description && (
               <span className="image-gallery-description" style={{ right: '0', left: 'initial' }} >
                 {item.description}
               </span>
             )}
           </a></div>
       )}
     </div>
   );
    }else{
      let image_width = parseInt(this.state.widthvalue*40)/100;
      let image_height = parseInt(this.state.widthvalue*60)/100;

    if(this.state.previewdata.pre_meta_img_width==""){  
     return(<div className="slide-image-data">
      <img  src={default_url} style={{ width: "100%",height:"100%"}} />
      </div>)
    }else{
      if(this.state.previewdata.pre_meta_img_width===this.state.previewdata.pre_meta_img_height){
        return ( <div className="slide-image-data">  
                <img  src={item.original} width="100%" height="auto" />
        </div>);
      }
      else{
        return ( <div className="slide-image-data">  
                 <img  src={item.original} style={{ width:this.state.widthvalue,height:image_height}} />
                 </div>);
      }
    }  
  }
   
 }
_renderVideo_profile(item) {
    let dropzoneStyle = {
      width: 400,
      height: 400,
    };

  return (
    // this.state.imagedata.Map_Place_Thumb_Photo
    <div className="slide-image-data">
      {this.state.showVideo[item.embedUrl] ? (
        <div className="video-wrapper-travel">
          <a className="close-video"  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}></a>
          <iframe
            width="400px"
            height="400px"
            src={item.embedUrl}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <div>
           <svg viewBox="0 0 100 100" className="image_profile_post" style={{ backgroundColor:"#cccccc"}}>
<defs><pattern id="Simg1" patternUnits="userSpaceOnUse" width="100%" height="auto">
  <img  href={item.original} style={{ width: "100%",height:"400px"}} ></img>
  </pattern> </defs><polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg1)" className="strokeclass">  </polygon></svg>
          </div>
      )}
    </div>
  );
}


  _removeHttps(url) {
    return url.replace(/^https?:\/\//, '');
  }
  _onClickText(textval){
    if(false==textval) {
      this.setState({showtext:true});
     // console.log(this.state.showtext);

    }
    else {
      this.setState({showtext:false});
     // console.log(this.state.showtext);
    }
  }

  _onClickTextSpread(textval){
    if(false==textval) {
      this.setState({showtextSpread:true});
     // console.log(this.state.showtext);

    }
    else {
      this.setState({showtextSpread:false});
     // console.log(this.state.showtext);
    }
  }
 
 
   _disableButton = () => {
    this.setState({setButtonDisabled:true});
    alert("Button has been disabled!");
};

_stripSpecialChars(text) {
  return text =text.replace(/(\n|\r|[^a-zA-Z0-9])/g,'');
  /// text;
   
}

_decodeHTMLEntities(str) {
  if(str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
     }
  return str;
}

_addLinks(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g+ /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
   let str = text.replace(urlRegex, function(url) {
    return '<a href="' + url + '">' + url + '</a>';
  })
  if(str==null){
    return text;
  }
}
_extract(str) { 
  const email =  
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g; 
  return str.match(email); 
} 

  render() {
let userlevel =0;
let image_level ="";
userlevel = this.state.imagedata.Total_Explurged_Level;
    switch(userlevel){
      case "0" :
        image_level=levelicon0;break;
      case "1" :
        image_level=levelicon1;break
      case "2" :
        image_level=levelicon2;break
      case "3" :
        image_level=levelicon3;break
      case "4" :
        image_level=levelicon4;break
      default:
           image_level=levelicon5;break
      }
  
      let no_image_data="";
    
      let image_check_map= this._checkIfImageExists(this.state.imagedata.Wall_Post_Map_Url,(exists) => {
        if (exists) {
          return true;
        } else {
          return false;
        }
      });      
      let image_check1= this._checkIfImageExists(this.state.imagedata.Wall_Post_Map_Url,(exists) => {
        if (exists) {
          return true;
        } else {
          let no_image_data="";
          return false;
        }
      });

    let image_check2= this._checkIfImageExists(this.state.imagedata.Map_Place_Thumb_Photo,(exists) => {
      if (exists) {
        return true;
      } else {
        let no_image_data="";
        return false;
      }
    });

    if(this.images.length==0) {this.images = this._getStaticImages();}
    if (this.images.length > 0) {
      if (this.images.length == 1) this.state.showBullets = false;
      else this.state.showBullets = true;
    }
    
    let clean_text ="";
    let data_pro_status="";
    if(this.state.imagedata.Post_Type==9){  clean_text = decodeURIComponent(this.state.imagedata.Post_Text);}
    else{
        clean_text = this.state.imagedata.Post_Text;
        clean_text = decode(clean_text);
        console.log(clean_text);
        data_pro_status = clean_text.includes("%0A");
        if(clean_text.includes("%0A")==true){
          clean_text= clean_text.replace(/%20/g,' '); 
         }else{  
          clean_text = decodeURIComponent(clean_text); 
         }
       }   

    let captiondata = clean_text.length;
    let share_clean_text=decodeURIComponent(this.state.imagedata.Shared_Post_Text); 
    let sharelen = share_clean_text.length;
    let meta_title = DOMPurify.sanitize(this.state.previewdata.pre_meta_title);
    let kudodata = this.state.imagedata.Engagement_User_Paths;    
    if(kudodata==null)  kudodata=null;
    let image_check_place="";    
    let content ="";     
    let domain_name=[];
   if(this.state.previewdata.pre_meta_url!=""){  domain_name = decodeURIComponent(this.state.previewdata.pre_meta_url).toLowerCase();
    console.log(domain_name);
    if(domain_name!=""){
     domain_name=  this._removeHttps(domain_name);
     domain_name=  domain_name.split("/");  
     console.log(domain_name);
     let checkwww = domain_name[0].startsWith("www.");
    if(checkwww==true) domain_name[0] = domain_name[0]?.replace("www."," ");
    else domain_name[0] = domain_name[0];
    }
  }else{
    domain_name[0]="App.Explurger.com";
  }

  let cu_date = new Date();
  var cu_date_format = new Date();  
     if(this.state.imagedata.Future_Travel_Date!="0000-00-00 00:00:00"){
      let date_future = moment(this.state.imagedata.Future_Travel_Date).format('yyyy-MM-DD');
      let created_date = moment(this.state.imagedata.Created_DateTime).format('yyyy-MM-DD');
  
    let  mt = this._getMonthDifference(new Date(created_date),new Date(date_future));
     this.state.travelmonth = (mt < 10 ? '0'+mt : mt);
      let d1 = new Date(moment(this.state.imagedata.Future_Travel_Date).format('MM/DD/yyyy')); 
     let  d2 = new Date(moment(this.state.imagedata.Created_DateTime).format('MM/DD/yyyy')); 
  // console.log(d1);
    let diff = d1.getTime() - d2.getTime(); 
    let dt= diff / (1000 * 60 * 60 * 24); 
    this.state.travelday = (dt < 10 ? '0'+dt : dt);
     }

 let  d3 = new Date(moment(this.state.imagedata.Created_DateTime).format('MM/DD/yyyy')); 
  
//********************************************* */
  var today = new Date();  
  var localoffset =(today.getTimezoneOffset()/60);
  var destoffset = 0;
  var offset = destoffset-localoffset;
  var dz1 = new Date( new Date(this.state.imagedata.Created_DateTime).getTime());
  var dz2 = new Date( new Date().getTime());
   let post_diff = Math.abs(dz2.getTime() -dz1.getTime());
   let pt = parseInt(post_diff / (1000*60*60*24));
   let pt_hour = parseInt(post_diff / (1000*60*60))+localoffset;
   let pt_min =  parseInt(post_diff / (1000*60))+(localoffset*60);
   let pt_sec=   parseInt(post_diff / 1000)+(localoffset*60*60);
   let post_time="";
  if(pt>=1 && pt<7) post_time= parseInt(pt)+" day ago";
  if(pt_hour>=1 && pt_hour<=24) post_time= parseInt(pt_hour)+" hour ago";
  if(pt_min>=1 && pt_min<=59)  post_time  = parseInt(pt_min) +" min ago";
  if(pt_sec<=59)  post_time  = "Just now";
 //**************************************************/
 //*********************shared post************************ */
 var today = new Date();  
 var localoffset =(today.getTimezoneOffset()/60);
 var destoffset = 0;
 var offset = destoffset-localoffset;
 var sdz1 = new Date( new Date(this.state.imagedata.Shared_Post_Created_DateTime).getTime());
 var sdz2 = new Date( new Date().getTime());
  let shared_post_diff = Math.abs(sdz2.getTime() -sdz1.getTime());
  let spt = parseInt(shared_post_diff / (1000*60*60*24));
  let spt_hour = parseInt(shared_post_diff / (1000*60*60))+localoffset;
  let spt_min =  parseInt(shared_post_diff / (1000*60))+(localoffset*60);
  let spt_sec=   parseInt(shared_post_diff / 1000)+(localoffset*60*60);
  let shared_post_time="";
 if(spt>=1 && spt<7) shared_post_time= parseInt(spt)+" day ago";
 if(spt_hour>=1 && spt_hour<=24) shared_post_time= parseInt(spt_hour)+" hour ago";
 if(spt_min>=1 && spt_min<=59)  shared_post_time  = parseInt(spt_min) +" min ago";
 if(spt_sec<=59)  shared_post_time  = "Just now";
//**************************************************/
  if(this.state.imagedata.Miles_Explurged>="0" && this.state.imagedata.Post_Type>=0 && this.state.imagedata.Post_Shared!=""){
     if(this.state.imagedata.Post_Shared=="0"){    
    let map_image_check_place= this._checkIfImageExists(this.state.imagedata.Map_Place_Thumb_Photo,(exists) => {
      if (exists) {
        return true;
      } else {
        return false;
      }
    });
  
    let image_check_place= this._checkIfImageExists(this.state.imagedata.Map_Place_Thumb_Photo,(exists) => {
      if (exists) {
        return true;
      } else {
        return false;
      }
    });
    //month difference between two dates
    let ctext="";
    switch(this.state.imagedata.Post_Type){
      case '0': ctext+=this.state.imagedata.To_PlaceName;break;
      case '1': ctext+='';break;
      case '2': ctext+="";break;
      case '3': ctext+=this.state.imagedata.To_PlaceName+" on "; break;
      case '4': ctext+=this.state.imagedata.To_PlaceName+"<b>"+this.state.imagedata.From_PlaceName+"</b>by <b>"+this.state.imagedata.User_Travel_By+"</b>";break;
      case '5': ctext+="";break;
      case '7': ctext+="";break;
      case '8': ctext+=this.state.imagedata.Post_String+"<b>"+this.state.imagedata.To_PlaceName+"<b>"+this.state.imagedata.From_PlaceName+"</b>by <b>"+this.state.imagedata.User_Travel_By+"</b>";break;
      case '9': ctext+="";break;
      default : break;
    }
    let k=0;
  let data_length =0;    
  let data_length1  = parseInt(this.state.widthvalue*5);
  let data_length2=   parseInt(this.state.widthvalue*2);
  let link_desc = decodeURIComponent(this.state.previewdata.pre_meta_desc);
  let add_length_data = link_desc.substring(0,data_length1);
  ctext = parse(ctext); 
  
  clean_text = clean_text.replace("%23","@");
  clean_text=  clean_text.replace("%40","#");


if(this.state.imagedata.Media_FilePath.length==0) data_length = data_length1;
if(this.state.imagedata.Media_FilePath.length!=0) data_length = data_length2;

   return (           
     <div>
      <div className="row">
      <div className="col-sm-4"></div>
      <div className="col-sm-4 block-box justify-content-center" style={{width:this.state.widthvalue}}>      
        <div className="box-1">
            <div>
          <div className="block-pro">
             <div className="row">
              <div className="col-sm-1 img_data">
                <svg viewBox="0 0 100 100" className="image_profile">
                  <defs><pattern id="Simg1" patternUnits="userSpaceOnUse" width="100" height="100">
                    <image href={this.state.imagedata.Creater_Thumb_ProfilePic} width={100} height={100}></image></pattern> </defs><polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg1)" className="strokclass" >  </polygon></svg>
              </div>                                       
              <div className="col-sm-11  data-match">                                                      
                <span className="data_left"><strong>{this.state.imagedata.Creater_Name}&nbsp;</strong></span>
         {this.state.imagedata.Badge_Status!=2?(<div>
              {(this.state.imagedata.Badge_Status==0)?(<span></span>):(              
            <div>{this.state.imagedata.Profile_Type==0?(<div className="data_left"><img className="imgclass" src={statuspic2} width={15} height="auto"></img>&nbsp;</div>):(<div><img className="imgclass" src={statuspic1} width={15} height="auto" ></img></div>)}
            </div>  )}</div>):(<div></div>)}          
          {(this.state.imagedata.Profile_Type=="0" && (this.state.imagedata.Badge_Status=="1" || this.state.imagedata.Badge_Status=="2"))?(<span className='miledata'>(<span>{this.state.imagedata.Miles_Explurged}</span><span className="ml">Miles</span><img className="imgclassrun" src={trvelpic} width='18px'height='auto' ></img>)</span>):(<span></span>)}
          <span className="ml">{this.state.imagedata.Post_String}</span>                   
         {(this.state.imagedata.Post_Type=="0" || this.state.imagedata.Post_Type=="2" || this.state.imagedata.Post_Type=="3" || this.state.imagedata.Post_Type=="6") ?(<span>
          {this.state.imagedata.Post_Type=="0"?(<span className='miledata'><b>{this.state.imagedata.To_PlaceName}</b></span>):(<span></span>)}
          {this.state.imagedata.Post_Type=="2"?(<span  className='miledata'><b>{this.state.imagedata.To_PlaceName}</b>&nbsp;From&nbsp;<b>{this.state.imagedata.From_PlaceName}</b>&nbsp;by&nbsp;<b>{this.state.imagedata.User_Travel_By}</b></span>):(<span></span>)}
          {this.state.imagedata.Post_Type=="3"?( <span className='miledata'><b>{this.state.imagedata.To_PlaceName}</b>&nbsp;on&nbsp;<b>{moment(this.state.imagedata.Future_Travel_Date).format('DD MMM YYYY')}</b></span>):(<span></span>)}
          {this.state.imagedata.Post_Type=="6"?( <span className='miledata'></span>):(<span></span>)}
          </span>):(<span></span>)}
          {this.state.imagedata.Tagged_User_Name!=""?( <span className='miledata ml'> along with <b>{this.state.imagedata.Tagged_User_Name}</b></span>):(<span></span>)}
          <br/>
          <span className="date-color"> 
          { pt>6?(moment(this.state.imagedata.Created_DateTime).format('DD MMM YY')):(post_time)}
          </span>                                                            
          </div>              
            </div>
            <div className="row"></div>
            {clean_text!="" && this.state.imagedata.Post_Type!=9?(
            <div className="row">  
            {data_pro_status==true?(            
               <div className="col-sm-12 caption_data overflow-auto" >
                <div className="capstext ">
                    {this.state.showtext==true ?(<div><div className="textml">{clean_text?.split('%0A').map(k =>{
                        return <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(k))+"<br/>" }}/>
                    })}</div><div><a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
                     <div>{this.state.showtext==true?"less":"more"}</div>
                      </a></div></div>):(<div><div className="truncate textml" >
                      {clean_text.substring(0,data_length)?.split('%0A').map(k =>{
                    return <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(k))+"<br/>" }}/>
                      })} 
                </div><div ><a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
                      <div>{this.state.showtext==true?"less":"more"}</div>
                       </a></div></div>)}
                </div>                   
             </div>
             ):(<div className="col-sm-12 caption_data overflow-auto" >              
             {this.state.showtext==true ?(  <div className="capstext">
                                      <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(clean_text)) }}/>
                                  </div>) :(<div className="capstext subtruncate" > 
                                   <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(clean_text.substring(0,data_length))) }}/>
                           </div>)}               
             <div className="textml"> <a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
              {captiondata>data_length?(<div> {this.state.showtext==true  ? "less" : "more"}</div>):(<div></div>)}
               </a></div>            
            </div>)}            
            </div>
            ):(<div><a href={clean_text}>{clean_text}</a></div>)}
            </div>    
            <br/> 
           {(this.state.imagedata.Post_Type!=5 && this.state.imagedata.Post_Type!=9)?(<div><div className="pull-right logo">&nbsp;&nbsp;<img src={image_level} width="50px" height="50px" className="logo" /></div></div>):(<div></div>)}
     {this.state.imagedata.Post_Type<9?(<div>
           {this.state.mediapath.length > 0 ? (
          <div className="box-1">            
          <section className="app">
          {this.imgdata.data[0].Map_Place_Thumb_Photo == "" ? (
            <ImageGallery
              ref={(i) => (this._imageGallery = i)}
              items={this.images}
              onClick={this._onImageClick.bind(this)}
              onImageLoad={this._onImageLoad}
             onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
             onScreenChange={this._onScreenChange.bind(this)}
              onPlay={this._onPlay.bind(this)}
              infinite={this.state.infinite}
              showBullets={this.state.showBullets}
              showFullscreenButton={
                this.state.showFullscreenButton &&
                this.state.showGalleryFullscreenButton
              }
              showPlayButton={
                this.state.showPlayButton && this.state.showGalleryPlayButton
              }
              showThumbnails={this.state.showThumbnails}
              showIndex={this.state.showIndex}
              showNav={this.state.showNav}
              isRTL={this.state.isRTL}
              thumbnailPosition={this.state.thumbnailPosition}
              slideDuration={parseInt(this.state.slideDuration)}
              slideInterval={parseInt(this.state.slideInterval)}
              slideOnThumbnailOver={this.state.slideOnThumbnailOver}
              additionalClass=""
              useWindowKeyDown={this.state.useWindowKeyDown}
            />) : 
            (<ImageGallery ref={(i) => (this._imageGallery = i)}
              items={this.images}
              onClick={this._onImageClick.bind(this)}
            //  onImageLoad={this._onImageLoad}
              onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)}
              onPlay={this._onPlay.bind(this)}
              infinite={this.state.infinite}
              showBullets={this.state.showBullets}
              showFullscreenButton={
                this.state.showFullscreenButton &&
                this.state.showGalleryFullscreenButton
              }
              showPlayButton={
                this.state.showPlayButton && this.state.showGalleryPlayButton
              }
              showThumbnails={this.state.showThumbnails}
              showIndex={this.state.showIndex}
              showNav={this.state.showNav}
              isRTL={this.state.isRTL}
              thumbnailPosition={this.state.thumbnailPosition}
              slideDuration={parseInt(this.state.slideDuration)}
              slideInterval={parseInt(this.state.slideInterval)}
              slideOnThumbnailOver={this.state.slideOnThumbnailOver}
              additionalClass=""
              useWindowKeyDown={this.state.useWindowKeyDown}
            />)}               
        </section></div>
           ):(<div></div>)} 
     <div className="card_add">      
      { (this.state.imagedata.Post_Type==3 && this.state.mediapath.length!="") ?(
              <div className="btn-text-travel">
              <div className="col-sm-12">
                  <div className=" col-sm-5 travel_data">EXPLURGE BEGINS IN </div>
                  <div className="col-sm-2 pic-travel">
                    {this.state.widthvalue>450?(<img src={travel_runner} className="pic-travel_upper"  width={30} height={30} />):
                     (<img src={travel_runner} className="pic-travel"  width={30} height={30} />)}
                    </div>
                  <div className="col-sm-2 traveltxt">Monthly<br/><span className="travel_time">{this.state.travelmonth}</span></div>
                  <div className="col-sm-1 traveltxt"><br/>&nbsp;|&nbsp;</div>
                 {this.state.widthvalue>450?( <div className="col-sm-2 traveltxt_days_up">Days<br/><span className="travel_time">{this.state.travelday}</span></div>)
                 :(<div className="col-sm-2 traveltxt_days_down">Days<br/><span className="travel_time">{this.state.travelday}</span></div>)}
               </div>
             </div>):(<div></div>)}    
       </div>                  
    {(this.state.imagedata.Map_Place_Thumb_Photo == "" && this.state.imagedata.Wall_Post_Map_Url=="")?(<div></div>):(<div>
     {((this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Map_Place_Thumb_Photo!="") && this.state.imagedata.Post_Type==0 && (image_check1==true || image_check2!="undefined"))?(
                        <div className="box-2">
                        <div className="container">
                        <div>
                            {this.state.imagedata.Map_Place_Thumb_Photo != "" ? (<img src={this.state.imagedata.Map_Place_Thumb_Photo}  className="left_image" width={100} height={100} ></img>) : (<div></div>)}
                          </div>
                          <div>
                            {this.state.imagedata.Wall_Post_Map_Url != "" ? (<img src={this.state.imagedata.Wall_Post_Map_Url} className="right_image"  width="70%" height={100}></img>) : (<div></div>)}
                          </div>                          
                        </div></div>):(<div></div>)} 
   {/* no thumbphoto in url */}
    {(((this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Map_Place_Thumb_Photo != "") && this.state.imagedata.Post_Type==0) && (image_check2==false || image_check2=="undefined"))?(
                        <div className="box-2">
                        <div className="container">
                          <div>
                           <img src={this.state.imagedata.Wall_Post_Map_Url}  width={400} height={100} ></img>
                          </div>                    
                        </div></div>):(<div></div>)}
  {/* no thumbphoto */}
  {((this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Map_Place_Thumb_Photo == "" && this.state.imagedata.Post_Type==0))?(
                        <div className="box-2">
                        <div className="container">
                          <div>
                           <img src={this.state.imagedata.Wall_Post_Map_Url} width={400} height={100} ></img>
                          </div>                    
                        </div></div>):(<div></div>)}

          { (this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Post_Type!=0)?(<div className="box-2">
          <div>
          { ((this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Map_Place_Thumb_Photo != "") && image_check_place==false) ?( 
            <div></div>):( <div className="container">
             <img src={this.state.imagedata.Wall_Post_Map_Url}     className="leftimage_2" ></img>
            <img src={this.state.imagedata.Map_Place_Thumb_Photo}  className="rightimage_2" ></img>       
            </div>
           )}
          {((this.state.imagedata.Wall_Post_Map_Url!="" && this.state.imagedata.Map_Place_Thumb_Photo != "") && image_check_place==true) ?(    
             <div className="container">          
             <img src={this.state.imagedata.Wall_Post_Map_Url}     className="leftimage" ></img>
            <img src={this.state.imagedata.Map_Place_Thumb_Photo}  className="rightimage" ></img>       
            </div>):(<div className="row"></div>)}</div></div>):(<div></div>)}</div>)}
            </div>):(<div></div>)}
            {this.state.imagedata.Post_Type==3 && this.state.imagedata.Media_FilePath.length==0?(
              <div className="btn-text-travel_no">
               <div className="col-sm-12">
                  <div className=" col-sm-5 travel_data">EXPLURGE BEGINS IN </div>
                  <div className="col-sm-2 pic-travel">
                     <img src={travel_runner} className="pic-travel"  width={40} height={40} />
                    </div>
                  <div className="col-sm-2 traveltxt">Months<br/><span className="travel_time">{this.state.travelmonth}</span></div>
                  <div className="col-sm-1 traveltxt"><br/>&nbsp;|&nbsp;</div>
                  {this.state.widthvalue>450?( <div className="col-sm-2 traveltxt_days_up">Days<br/><span className="travel_time">{this.state.travelday}</span></div>)
                 :(<div className="col-sm-2 traveltxt_days_down">Days<br/><span className="travel_time">{this.state.travelday}</span></div>)}
               </div>                
               </div>):
               (<div></div>)}
      {this.state.imagedata.Post_Type==9?(    
           <div>
             {(this.state.previewdata.pre_meta_img_width===this.state.previewdata.pre_meta_img_height) ? (
              <div className="box-5">
                <div className="row">
                   <div className="container">
                 {this.state.previewdata.pre_meta_img_width!=0?(   
                   <div>
                    <div className="col-sm-5 customedata">
                   <section className="app">
                      <ImageGallery
                        ref={(i) => (this._imageGallery = i)}
                        items={this.images}
                        onClick={this._onImageClick.bind(this)}
                        onImageLoad={this._onImageLoad}
                        onSlide={this._onSlide.bind(this)}
                        onPause={this._onPause.bind(this)}
                        onScreenChange={this._onScreenChange.bind(this)}
                        onPlay={this._onPlay.bind(this)}
                        infinite={this.state.infinite}
                        showBullets={false}
                        showFullscreenButton={
                          this.state.showFullscreenButton &&
                          this.state.showGalleryFullscreenButton
                        }
                        showPlayButton={
                          this.state.showPlayButton && this.state.showGalleryPlayButton
                        }
                        showThumbnails={this.state.showThumbnails}
                        showIndex={this.state.showIndex}
                        showNav={this.state.showNav}
                        isRTL={this.state.isRTL}
                        thumbnailPosition={this.state.thumbnailPosition}
                        slideDuration={parseInt(this.state.slideDuration)}
                        slideInterval={parseInt(this.state.slideInterval)}
                        slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                        additionalClass=""
                        useWindowKeyDown={this.state.useWindowKeyDown}
                      />                   
                  </section>
                   </div>
                  <div className="col-sm-7 textcolor">
                  <div className="col-sm-12">
                           {decodeURIComponent(this.state.previewdata.pre_meta_title)}<br/>
                           {decodeURIComponent(this.state.previewdata.pre_meta_url)}
                  </div>
                  </div>
                  </div>):(<div className="col-sm-12 textcolor_noimage">
                           {decodeURIComponent(this.state.previewdata.pre_meta_title)}<br/>
                           {decodeURIComponent(this.state.previewdata.pre_meta_url)}
                     </div>)}
                    <br/></div></div></div>) :
                    (<div className="box-1">
                    <div className="row profile-image-post-line">
                       <div className="container">
                         <section className="app">
                      <ImageGallery
                        ref={(i) => (this._imageGallery = i)}
                        items={this.images}
                       // onClick={this._onImageClick.bind(this)}
                        onImageLoad={this._onImageLoad}
                        onSlide={this._onSlide.bind(this)}
                        onPause={this._onPause.bind(this)}
                        onScreenChange={this._onScreenChange.bind(this)}
                        onPlay={this._onPlay.bind(this)}
                        infinite={this.state.infinite}
                        showBullets={false}
                        showFullscreenButton={
                          this.state.showFullscreenButton &&
                          this.state.showGalleryFullscreenButton
                        }
                        showPlayButton={
                          this.state.showPlayButton && this.state.showGalleryPlayButton
                        }
                        showThumbnails={this.state.showThumbnails}
                        showIndex={this.state.showIndex}
                        showNav={this.state.showNav}
                        isRTL={this.state.isRTL}
                        thumbnailPosition={this.state.thumbnailPosition}
                        slideDuration={parseInt(this.state.slideDuration)}
                        slideInterval={parseInt(this.state.slideInterval)}
                        slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                        additionalClass=""
                        useWindowKeyDown={this.state.useWindowKeyDown}
                      />
                     <div className="row textcolor-noimage">  
                     {domain_name[0]!=""?(<div className="row "><a href={decodeURIComponent(this.state.previewdata.pre_meta_url)} target="_blank" rel="noreferrer" className="domaindata">{domain_name[0].toUpperCase()}</a></div>):(<div></div>)}                 
                      {this.state.previewdata.pre_meta_title!=""?(<div className="row textcolor-noimage">
                       <p><b>{decodeURIComponent(this.state.previewdata.pre_meta_title)}</b>-{decodeURIComponent(add_length_data)}</p>
                    </div>):(<div></div>)} 
                    </div>
                   </section></div></div></div>)}</div>):(<div></div>)}
            {/* {this.state.imagedata.Post_Type==9?(<div></div>):(<div>&nbsp;</div>)} */}
       <div className="box-3">
          <div className="card_add">
              { (this.state.imagedata.Post_Type==0 || this.state.imagedata.Post_Type==2 )?(
             <div className="col-sm-12">
                <div className="btn-text-down">                      
                 {this.state.imagedata.LastExplurged_UserPath.length>0?(<div></div>):(<div className="btn-left">&nbsp;&nbsp;</div>)}
                  <div className="btn-bucket-center" dangerouslySetInnerHTML={{__html: this.state.imagedata.BucketLabelText}} />
                  <button className="btntxt">
                    {this.state.imagedata.Bucket_Status=="2"?(<img src={bucket_icon1} width={8} className="btnmark"></img>):(<span></span>)}
                    {this.state.imagedata.Bucket_Status=="1"?(<img src={bucket_icon2} width={8} className="btnmark"></img>):(<span></span>)}
                    {this.state.imagedata.Bucket_Status=="0"?(<img src={bucket_icon3} width={8} className="btnmark"></img>):(<span></span>)}
                    &nbsp;&nbsp;Bucket List</button>
                  </div>
                  </div>):(<div></div>)}
            </div>    
            </div> 
            <div className="row mob_line_top"></div>
             <div className="col-sm-12 side-upper">{(this.state.imagedata.Total_Post_Kudos>0 || this.state.imagedata.Total_Post_Shares>0)?(
                <div className="row">
                  <div className="kudo-alldata"> 
                  <div className="img-left">
                      {kudodata.slice(0, 3).map((item) => (                            
                       ( <img src={item.Kudos_User_Thumb_Pic} width={20} height={20} className="img-circle"></img>)
                       ))   }
                  </div>
                    {  this.state.imagedata.Total_Post_Kudos>0?( <div className="kudotext">&nbsp;{this.state.imagedata.Total_Post_Kudos }&nbsp;kudos</div>):(<div></div>)}
               </div> 
              <div className="sayonly">
              {(this.state.imagedata.Total_Post_Says>0 && this.state.imagedata.Total_Post_Shares>0)?(<div className="col-sm-12">
                <div className="dot-say">&nbsp;{this.state.imagedata.Total_Post_Says}&nbsp;say</div>
              <div className="dot"></div><div className="dot-spread">&nbsp;&nbsp;&nbsp;{this.state.imagedata.Total_Post_Shares}&nbsp;spread</div></div>):(<span></span>)}
              {(this.state.imagedata.Total_Post_Says<1 && this.state.imagedata.Total_Post_Shares>0) ?(<div className="col-sm-12 sayonly1">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.imagedata.Total_Post_Shares}&nbsp;spread</div>):(<span/>)}
              {(this.state.imagedata.Total_Post_Says>0 && this.state.imagedata.Total_Post_Shares<1) ?(<div className="col-sm-12  sayonly2">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.imagedata.Total_Post_Says}&nbsp;say</div>):(<span/>)}
              </div>
              </div>):(<div></div>)}</div>
              {(this.state.imagedata.Post_Type==9 || this.state.imagedata.Post_Type==0)?(<div></div>):(<div className="row mob_line_bottom"></div>)}
              <div className="row kudo-mark">
                <div className="footer-left-inner1">
                  <span className="pull-left text-muted my-tool-tip">
                    <img src={kudurl} className="kudimage" title="Kudos"></img><br/>Kudos
                  </span>
                </div>
                <div className="footer-left-inner2 "><img src={sayimage} width="20px"></img><br/>Say</div>
                <div className="footer-left-inner3 grayscale"><img src={shareimage} width="20px" className="grayscale"></img><br/>Spread</div>                      
              </div>
          </div>
       </div> 
      </div>   
      <div className="col-sm-4"></div>              
      </div>
      </div>
    );
  }else{ //without spread  
     return (
      <div className="row">
      <div className="col-sm-4"></div>
      <div className="col-sm-4 block-box justify-content-center" style={{width:360}} > 
      <div className="box-1" style={{width:350}}>
       <div className="block-pro">
           <div className="row">
            <div className="col-sm-1 img_data">
              <svg viewBox="0 0 100 100" className="image_profile">
                <defs><pattern id="Simg1" patternUnits="userSpaceOnUse" width="100" height="100">
                  <image href={this.state.imagedata.Creater_Thumb_ProfilePic} width={100} height={100}></image></pattern> </defs><polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg1)">  </polygon></svg>
            </div>                                       
            <div className="col-sm-11 data-match">
              <div>                                                     
              <span className="data_left">Spread&nbsp;by&nbsp;<strong>{this.state.imagedata.Shared_Post_Owner_Name}</strong>&nbsp;</span>
          {this.state.imagedata.Profile_Type==0?(<span></span>):(<span className="imgclass"> {this.state.imagedata.Badge_Status==0?(<img src={statuspic2} width={14} height={14}></img>):(<img src={statuspic1} width={14} height={14}></img>)}</span>)}
 {((this.state.imagedata.Badge_Status==1 || this.state.imagedata.Badge_Status==2) && (this.state.imagedata.Profile_Type==0))?(<span className='miledata'>(<span className="ml">{this.state.imagedata.Shared_User_Miles_Explurge}</span>Miles<span className="ml"><img src={trvelpic} width='20px' ></img></span>)&nbsp;</span>
                   ):(<span></span>)}
          {this.state.imagedata.Post_Type=="2"?(<span>&nbsp;<b>{this.state.imagedata.To_PlaceName}</b>&nbsp;From<b>{this.state.imagedata.From_PlaceName}</b>&nbsp;by&nbsp;<b>{this.state.imagedata.User_Travel_By}</b></span>):(<span></span>)}
          {this.state.imagedata.Post_Type=="3"?( <span>&nbsp;<b>{this.state.imagedata.To_PlaceName}</b>&nbsp;on&nbsp;<b>{moment(this.state.imagedata.Future_Travel_Date).format('DD MMM YYYY')}</b></span>):(<span></span>)}
          {this.state.imagedata.Post_Type=="6"?( <span>&nbsp;</span>):(<span></span>)}</div>
             {spt>6?(<span className="date-color-spread" >{moment(this.state.imagedata.Shared_Post_Created_DateTime).format('DD MMM YYYY')}</span>):(<span className="date-color-spread">{shared_post_time}</span>)}

             </div>              
          </div>
          {share_clean_text!=""?(<div>
            {/* <div className="col-sm-11 caption_data overflow-auto" >
                    {this.state.showtext==true ? this._urlifyshare_clean_text : `${share_clean_text.substring(0,100)}`}
                      <div onClick={this._onClickText.bind(this,this.state.showtext)}>
                     <a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
                     {sharelen>100?(<div> {this.state.showtext==true  ? "less" : "more..."}</div>):(<div></div>)}
                      </a>
                   </div>
          </div>*/}
             <div className="col-sm-11 caption_data overflow-auto"> 
                    {this.state.showtext==true ?(<div><div className="textml">{share_clean_text?.split('%0A').map(k =>{
                        return <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(k))+"<br/>" }}/>

                    })}</div><div><a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
                     <div>{this.state.showtext==true?"less":"more"}</div>
                      </a></div></div>):(<div><div className="truncate textml" >
                      {share_clean_text.substring(0,this.state.widthvalue*2)?.split('%0A').map(k =>{
                    return <span dangerouslySetInnerHTML={{ __html: decode(this._urlify(k))+"<br/>" }}/>
                      })} 
                </div><div ><a href="#" onClick={this._onClickText.bind(this,this.state.showtext)}>                        
                      <div>{this.state.showtext==true?"less":"more"}</div>
                       </a></div></div>)}
                </div>
             
             <div className="col-sm-1"></div></div>):(<div></div>)}                   
         </div>
         <div classname="row"></div>
      <div className="">
      <div className="row">             <div className="box-spraed-1" >
                  <div className="row">
                    <div className="col-sm-1 img_data">
                      <svg viewBox="0 0 100 100" className="image_profile_spread">
                        <defs><pattern id="Simg1" patternUnits="userSpaceOnUse" width="75" height="75">
                          <image href={this.state.imagedata.Creater_Thumb_ProfilePic} width={75} height={75}></image></pattern> </defs><polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg1)">  </polygon></svg>
                    </div>
                    <div className="col-sm-11 post-details-inner">
                     <div className="post-details-inner"> 
                        <span><strong>{this.state.imagedata.Creater_Name}</strong>&nbsp;</span>
                    {this.state.imagedata.Shared_Badge_Status==2?(<span></span>):(<span>
                    {this.state.imagedata.Shared_Badge_Status==0?(<img src={statuspic2} width={14} height={14}></img>):(<span></span>)}
                    {this.state.imagedata.Shared_Badge_Status==1?(<img src={statuspic1} width={14} height={14}></img>):(<span></span>)}
                   {((this.state.imagedata.Badge_Status==1 || this.state.imagedata.Badge_Status==2) && this.state.imagedata.Profile_Type==0)?(<span className='miledata'>(<span className="ml">{this.state.imagedata.Miles_Explurged}</span>Miles<span className="ml"><img src={trvelpic} width='20px' ></img></span>)&nbsp;</span>
                   ):(<span></span>)}<span>&nbsp;{this.state.imagedata.Post_String}</span><span>&nbsp;<b>{this.state.imagedata.To_PlaceName}</b></span></span>)}
                   </div>
                   {pt>6?(<span className="date-color-spread-inner">{moment(this.state.imagedata.Created_DateTime).format('DD MMM YYYY')}</span>):(<span>{post_time}</span>)} 
                    </div>
                  </div>
                {clean_text!=""?(
                    <div className="row">
                      {share_clean_text!=""?(<div>
              <div className="col-sm-11 caption_data overflow-auto" >
                      {this.state.showtextSpread==true ? this._urlify(clean_text) : `${this._urlify(clean_text.substring(0,(this.widthvalue*2)))}`}
                        <div onClick={this._onClickTextSpread.bind(this,this.state.showtextSpread)}>
                      <a href="#" onClick={this._onClickTextSpread.bind(this,this.state.showtextSpread)}>                        
                      {captiondata>100?(<div> {this.state.showtext==true  ? "less" : "more..."}</div>):(<div></div>)}
                        </a>
                    </div>
              </div><div className="col-sm-1"></div></div>):(<div></div>)}                    
                    </div>):(<div> <span><i class="fa fa-smile-o"></i></span></div>)}  
                    {this.state.mediapath.length>0?(<div>                  
                    <div className="box-4">
                    <div className="col-sm-10" ></div>
                    <div className="col-sm-2 logo-spread">&nbsp;&nbsp;<img src={image_level} width="40px" height="40px" className="logo-spread" /></div>                   
                    <div style={{width:this.widthvalue}}>
                    <section className="app">
                      {this.state.mediapath.length > 0 ? (
                        <ImageGallery
                          ref={(i) => (this._imageGallery = i)}
                          items={this.images}
                          onClick={this._onImageClick.bind(this)}
                          onImageLoad={this._onImageLoad}
                          onSlide={this._onSlide.bind(this)}
                          onPause={this._onPause.bind(this)}
                          onScreenChange={this._onScreenChange.bind(this)}
                          onPlay={this._onPlay.bind(this)}
                          infinite={this.state.infinite}
                          showBullets={false}
                          showFullscreenButton={
                            this.state.showFullscreenButton &&
                            this.state.showGalleryFullscreenButton
                          }
                          showPlayButton={
                            this.state.showPlayButton && this.state.showGalleryPlayButton
                          }
                          showThumbnails={this.state.showThumbnails}
                          showIndex={this.state.showIndex}
                          showNav={this.state.showNav}
                          isRTL={this.state.isRTL}
                          thumbnailPosition={this.state.thumbnailPosition}
                          slideDuration={parseInt(this.state.slideDuration)}
                          slideInterval={parseInt(this.state.slideInterval)}
                          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                          additionalClass=""
                          useWindowKeyDown={this.state.useWindowKeyDown}
                        />) : (<div></div>)}
                    </section>
                    </div>  
                    </div></div>):(<div></div>)}
              {this.state.imagedata.Map_Place_Thumb_Photo != ""?(<div>
                    {(this.state.imagedata.Map_Place_Thumb_Photo == "" && this.state.imagedata.Wall_Post_Map_Url!="" ) ? (<div>
                      <img src={this.state.imagedata.Wall_Post_Map_Url} className="explurge-travel-map-map" ></img>
                    </div>) : (<div></div>)}
                    {(this.state.imagedata.Map_Place_Thumb_Photo != "" && this.state.imagedata.Wall_Post_Map_Url!="" ) && (this.state.imagedata.Post_Type==0) ? (
                    <div className="container">
                        {this.state.imagedata.Map_Place_Thumb_Photo != "" ? (<img src={this.state.imagedata.Map_Place_Thumb_Photo} className="left_image" ></img>) : (<div></div>)}                    
                        {this.state.imagedata.Wall_Post_Map_Url != "" ? (<img src={this.state.imagedata.Wall_Post_Map_Url} className="right_image" ></img>) : (<div></div>)}
                    </div>):(<div></div>)}   
                   {(this.state.imagedata.Wall_Post_Map_Url!= "" && (this.state.imagedata.Post_Type!=0)) ?(
                        <div className="container">
                          <div>
                            {this.state.imagedata.Wall_Post_Map_Url != "" ? (<img src={this.state.imagedata.Wall_Post_Map_Url} className="left_image_spread" width={318} height={100}></img>) : (<div></div>)}
                          </div>
                          <div>
                            {(this.state.imagedata.Map_Place_Thumb_Photo != "" && this.state.imagedata.Wall_Post_Map_Url != "" )?(<img src={this.state.imagedata.Map_Place_Thumb_Photo} className="right_image_spread" width={135} height={100} ></img>) : (<div>
                            </div>)}
                          </div>
                      </div>):(<div></div>)} 
              </div>):(<div></div>)}
                   
        </div>
              <div className="box-3">
              <div className="card_add">   
              <div className="row">
              { this.state.imagedata.Post_Type==3?(
                <div className="btn-text-travel">
                <div className="col-sm-12">
                    <div className=" col-sm-5 travel_data">EXPLURGE BEGINS IN </div>
                    <div className="col-sm-2 pic-travel">
                      <img src={travel_runner} className="pic-travel"  width={30} height={30} />
                      </div>
                    <div className="col-sm-2 traveltxt">Monthls<br/><span className="travel_time">{this.state.travelmonth}</span></div>
                    <div className="col-sm-1 traveltxt"><br/>&nbsp;|&nbsp;</div>
                    <div className="col-sm-2 traveltxt_days_up">Days<br/><span className="travel_time">{this.state.travelday}</span></div>
                </div> </div>):(<div></div>)}    
                </div>      
                <div className="row">  
                { (this.state.imagedata.Post_Type==2 || this.state.imagedata.Post_Type==0)?(
                  <div className="col-sm-12 btn-text-spread">
                  <div className="btn-inner-spread">                      
                    <div className="btn-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="btn-bucket-center" dangerouslySetInnerHTML={{__html: this.state.imagedata.BucketLabelText}} />
                    <button className="btntxt_spread"><img src={bucket_icon1} width={9} ></img>&nbsp;&nbsp;Bucket List</button>
                    </div>
                 </div>):(<div></div>)}</div>
              <div className="row mob_line_top"></div>
                <div className="row footer-images">
                  <div className="col-sm-4 footer-left-inner1">
                    <div>
                {  kudodata!=null?(<div className="img-left">   {kudodata.map((item) => (                              
                        ( <img src={item.Kudos_User_Thumb_Pic} width={20} height={20} className="img-circle"></img>)
                        ))   } </div>):(<div className="img-left"></div>)}
                      {  this.state.imagedata.Total_Post_Kudos>0?( <div>{this.state.imagedata.Total_Post_Kudos}&nbsp;kudos</div>):(<div></div>)}
                    </div>
                    </div>
                  <div className="col-sm-2 footer-left-inner2">
                  {  this.state.imagedata.Total_Post_Says>0?( <div>{this.state.imagedata.Total_Post_Says}&nbsp;say</div>):(<div></div>)}
                  </div>                        
                  <div className="col-sm-6 footer-left-inner2 grayscale">{this.state.imagedata.Total_Post_Shares>0?( <div>{this.state.imagedata.Total_Post_Shares}&nbsp;spread</div>):(<div></div>)}&nbsp;</div>
                </div>
                <div className="row"></div>                
                <div className="row side-upper mob_line_bottom"></div>
                  <div className="col-sm-4 footer-left-inner1">
                    <span className="pull-left text-muted my-tool-tip">
                      <img src={kudurl} className="kudimage" title="Kudos"></img><br/>Kudos
                    </span>
                  </div>
                  <div className="col-sm-2 footer-left-inner2 "><img src={sayimage} width="25px"></img><br/>Say</div>
                  <div className="col-sm-6 footer-left-inner2 grayscale"><img src={shareimage} width="25px" className="grayscale"></img><br/>Spread</div>
              </div>    
              </div>
       </div>
      </div> 
      </div>
       </div>
      <div className="col-sm-4"></div>
     </div>
            );    
  } }else{

    return (<div>
            <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4 blank-box">
            <div className="center"> 
              <img src={loader} width='300' height='300' ></img>
           </div>
           </div>
           <div className="col-sm-4"></div></div></div>);
    }
}
}

//export default MyVideoSlider;
export default withRouter(MyVideoSlider);
