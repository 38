import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import logo from "./company.jpg"; // relative path to image 
import user_default from "./Hexagon.jpg"; // relative path to image 
import loader from './app-images/loader.gif';

import * as constants from '../constants';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
const kudurl = constants.ADMIN_BASE_URL + 'images/kudos_gray.png';

const image_profile_url = constants.BUCKET_URL + "media/users/s138tfgjas58qqu2ll7yega061662965046437/profilepics/pic_profile/1667460827681.jpg";

class MyVideoSlider extends React.Component {
  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: false,
      showFullscreenButton: true,
      showGalleryFullscreenButton: false,
      showPlayButton: false,
      showGalleryPlayButton: true,
      showNav: false,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: true,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
    };

    this.images = [
      {
        thumbnail: `https://i3.ytimg.com/vi/sGvh-WJjK60/hqdefault.jpg`,
        original: `https://i3.ytimg.com/vi/sGvh-WJjK60/maxresdefault.jpg`,
        embedUrl: 'https://cdn.explurger.com/media/ads/video/1695376786350ad.mp4',
        //description: 'Render custom slides (such as videos)',
        renderItem: this._renderVideo.bind(this),
      },
      {
        thumbnail: `https://i3.ytimg.com/vi/tYGh2qnCl9I/hqdefault.jpg`,
        original: `https://i3.ytimg.com/vi/tYGh2qnCl9I/maxresdefault.jpg`,
        embedUrl: 'https://cdn.explurger.com/media/ads/video/16950321179549x1611.mp4',
       // description: 'Render custom slides (such as videos)',
        renderItem: this._renderVideo.bind(this),
      },
       {
        thumbnail: `https://res.cloudinary.com/ifeomaimoh/image/upload/v1652366604/demo_image5.jpg`,
        original: `https://i3.ytimg.com/vi/tYGh2qnCl9I/maxresdefault.jpg`,
        embedUrl: 'https://cdn.explurger.com/media/ads/video/1695376786350ad.mp4',
       // description: 'Render custom slides (such as videos)',
        renderItem: this._renderVideo.bind(this),
      },
      {
        original: `${PREFIX_URL}1.jpg`,
        thumbnail: `${PREFIX_URL}1t.jpg`,
        originalClass: 'featured-slide',
        thumbnailClass: 'featured-thumb',
     //   description: 'Custom class for slides & thumbnails',
      },
    ].concat(this._getStaticImages());
  }

  _onImageClick(event) {
    console.debug(
      'clicked on image',
      event.target,
      'at index',
      this._imageGallery.getCurrentIndex()
    );
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({ [state]: event.target.value });
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _getStaticImages() {
    let images = [];
    for (let i = 2; i < 4; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail: `${PREFIX_URL}${i}t.jpg`,
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="auto"
              height="auto"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: '0', left: 'initial' }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  render() {
    return (<div>
      <div className="row">
      <div className="col-sm-4"></div>
      <div className="col-sm-4 blank-box">
      <div className="center"> 
        <img src={loader} width='300' height='300' ></img>
     </div>
     </div>
     <div className="col-sm-4"></div></div></div>);
  }
}

export default MyVideoSlider;
