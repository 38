import * as React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import {Helmet} from "react-helmet";
import * as constants from './constants';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Helmet>
        <title>{constants.APP_TITLE}</title>
        <meta property="og:description" content={constants.APP_DESCRIPTION} />
        <meta name="description" content={constants.APP_DESCRIPTION} />
        <meta name="keywords" content={constants.APP_KEYWORDS} />
    </Helmet>    
    <App />
  </StrictMode>
);
