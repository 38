import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from "react-router-dom";
// import 'react-awesome-slider/dist/styles.css';
import HashLoader from "react-spinners/ClipLoader";
//import {Helmet} from "react-helmet";

import {isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet} from 'react-device-detect';

import '../style.css';
import '../player.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";


//import mapProfileMap from '../assets/icon/Profile/map_profile_map.imageset/map_profile_map.png';

import profilePlace from '../assets/icon/Place/place.imageset/place.jpg';
import profileShare from '../assets/icon/Share/bm_share_black.imageset/bm_share_black.png';

import placeHolder from '../assets/img/placeholder.imageset/placeholder@2x.png';

import splashScreen from '../assets/img/sonu-sood.jpeg';

import * as constants from '../constants';

import ViewPost from './ViewPost';
import CommonComponent from './CommonComponent';
import TopComponent from './TopComponent';


import useWindowDimensions from '../hooks/useWindowDimensions';
import ReactHtmlParser from 'react-html-parser';
import {localStorageTTL, getLocalStorage, setLocalStorage} from '../hooks/localStorageWithTTL';

// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function ViewProfile() {

    const { id } = useParams();
    const [postData, setPostData] = useState([]);
    const [postCreatorInfo, setPostCreatorInfo] = useState([]);
    const [isActive, setIsActive] = useState(true);
   
    const dontDismissModelHide = useRef(true);

    const initialized = useRef(false);

    function handleDataFromChild(data) {
        /*
            { Creater_Name: Creater_Name, Creater_ProfilePic: Creater_ProfilePic }
        */
        var mergeResult = postCreatorInfo;
        var key, value;
        for ([key, value] of Object.entries(data)) {
            mergeResult[key] = value;
        }
        setPostCreatorInfo(mergeResult);
    }    

    function handleDataFromChildCommonComponent(data) {
        dontDismissModelHide.current = data;
    }    

    function handleDataFromChildDontDismissModelHide(data) {        
        dontDismissModelHide.current = data;
    }  
    
    const redirctToAppOrWebsite = () => {
        //isMobile, isAndroid, isIOS, isWinPhone
        //console.log("redirctToAppOrWebsite-",isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet);
        var url = constants.APP_WEBSITE_URL;       
        if((isMobile || isTablet) && isAndroid)
        {
            url = constants.APP_GOOGLE_PLAY_URL;
        }
        else if((isMobile || isTablet) && isIOS)
        {
            url = constants.APP_APPLE_APP_STORE_URL;
        }             
        //console.log("redirctToAppOrWebsite-",url);   
        //window.open(url);
        window.location.replace(url);
    }    

    useEffect(() => {
        //console.log("width, height-", width, height);
    //}, [width, height]);
    }, []);    
      
    var setFetchedPostData = async (postDataReversed) => {
        //console.log("setFetchedPostData ", postDataReversed);

        var User_Status = postDataReversed.data?.User_Status;
        var Block_Status = postDataReversed.data?.Block_Status;

        console.log("User_Status ", User_Status);
        console.log("Block_Status ", Block_Status);
        
        // check user blocked/deleted/de-active
        if(
            (User_Status != undefined && User_Status == "1")  //user deleted/deactive
            || 
            (Block_Status != undefined && Block_Status != "0") //user blocked
        ) 
        {
            // do redirect to website/app/app store
            redirctToAppOrWebsite();
            return;
        }       
        

        var Creater_Name = postDataReversed.data.User_FirstName;
        var Creater_ProfilePic = postDataReversed.data.User_Wall_ProfilePic;
        // Cover_BG_Color : "#868586"
        var User_Profile_Cover = postDataReversed.data.User_Profile_Cover;
        var Cover_BG_Color = postDataReversed.data.Cover_BG_Color;
        var User_Handle = postDataReversed.data.User_Handle;
        var User_City = postDataReversed.data.User_City;
        var User_Country = postDataReversed.data.User_Country;
        // var Post_Text = decodeURIComponent(post.Post_Text);

        var User_Bio = postDataReversed.data.User_Bio;
        //var User_Bio = decodeURIComponent(postDataReversed.data.User_Bio);



        var Level_Img_Icon = postDataReversed.data.Level_Img_Icon;
        var Level_Name = postDataReversed.data.Level_Name;

        var explurgerSummary = postDataReversed.explurgerSummary
        var i,summary;
        var explurgerSummaryData = [];
        for(i=0;i<explurgerSummary.length;i++)
        {
            summary = explurgerSummary[i];
            explurgerSummaryData[summary.Summary_Type] = summary;
        }
        //console.log(explurgerSummary,explurgerSummaryData);

        // Explurge_Msg
        var Post_Count = postDataReversed.data.Post_Count;
        //Post_Count
        var Explurge_Msg = postDataReversed.data.Explurge_Msg;

        // User_Gender : "0" 0:Male,1:Female:,

        // var User_Gender = postDataReversed.data.User_Gender;

        // var User_Gender_Text = "";
        // if(User_Gender == "0")
        // {
        //     User_Gender_Text = "Male";
        // }
        // else if(User_Gender == "1")
        // {
        //     User_Gender_Text = "Female";
        // }

        // Badge_Status
        var Badge_Status = postDataReversed.data.Badge_Status;

        // Profile_Type : "1"
        var Profile_Type = postDataReversed.data.Profile_Type;
        

        if(Creater_ProfilePic==null || Creater_ProfilePic=="")
            Creater_ProfilePic = placeHolder;
        
        setPostCreatorInfo({ 
            Creater_Name: Creater_Name, 
            Creater_ProfilePic: Creater_ProfilePic, 
            User_Profile_Cover:User_Profile_Cover,
            Cover_BG_Color:Cover_BG_Color,
            User_Handle:User_Handle,
            User_City:User_City,
            User_Country:User_Country,
            User_Bio:User_Bio,
            //User_Bio2:User_Bio2,
            Level_Img_Icon:Level_Img_Icon,
            Level_Name:Level_Name,
            explurgerSummaryData:explurgerSummaryData,
            Post_Count:Post_Count,
            Explurge_Msg:Explurge_Msg,
            //User_Gender_Text:User_Gender_Text,
            Badge_Status:Badge_Status,
            Profile_Type:Profile_Type
        });

        setPostData(postDataReversed);

        setIsActive(false);  // Loader false

        //localStorageTTL, getLocalStorage, setLocalStorage

        setLocalStorage('profile_data', postDataReversed, localStorageTTL);

    }

    var noProfile = async () => {
        setIsActive(false);  // Loader false
    }


    var getPostData = async (title, body) => {
        try {

            var body = {
                "User_TokenKey": id,
                "User_Friends_TokenKey": id
            }
            await fetch(constants.API_BASE_URL + constants.profileSummary, {
                method: 'POST',
                body: JSON.stringify(body),
                title: title,
                reqId: Math.random().toString(36).slice(2),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then((response) => response.json())
            .then((data) => {   
                var d = 0;             
                if(data.status && data.code == 200)
                {
                    //var postData = data.data;
                    //if(postData != undefined && postData != null && postData.length >0)
                    {
                        d = 1;
                        //const postDataReversed = postData.reverse();                    
                        setFetchedPostData(data);
                    }
                }   
                if(d == 0)
                {
                    noProfile();
                }                 
            })
            .catch((error) => {
                console.log("getPostData-",error);
                noProfile();
            });
        }
        catch (e) {
            console.log("e ", e)
            setPostData([])
        }
    }
       

    useEffect(() => {

        if (!initialized.current) {
            initialized.current = true; 

            if(window.location.hostname == 'localhost' && 1==0)
            {
                var postDataReversed = getLocalStorage('profile_data');
          
                if(postDataReversed == null)
                {
                    getPostData("Post Details", {}); 
                }
                else
                {
                    setFetchedPostData(postDataReversed);
                }  
            }
            else
            {
                getPostData("Post Details", {}); 
            }         
        }          

    }, []);


    const postProfileView = () => {
        // Badge icon
        // Badge_Status : "1"
        // Profile_Type : "1"
        if (postCreatorInfo.Profile_Type == "1" && postCreatorInfo.Badge_Status == "1") {
            var badgeImg = constants.ADMIN_BASE_URL + "images/business_badge@2x.png"
            var Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } else if (postCreatorInfo.Profile_Type == "0" && postCreatorInfo.Badge_Status == "1") {
            var badgeImg = constants.ADMIN_BASE_URL + "images/verified-icon.png"
            var Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } else {
            var badgeImg = "";
            var Badge_Img = "";
        }

        var backgroundColor = postCreatorInfo.Cover_BG_Color;

        if(postCreatorInfo.User_Profile_Cover == "")
            backgroundColor = "#556080";

            return (<>
                <div className="row">
                    <div className="col col-sm-12 p0 mt10 profile-img-box" style={{backgroundColor:backgroundColor }}>
                        <img className='profile-img' src={postCreatorInfo.User_Profile_Cover}>
                        </img>
                    </div>
                </div>
                <div className="row mtminus60 explr-profile p10">
                    <div className="col-sm-4 explr-col-3 p0 mtb10">
                        <svg viewBox="0 0 100 100" className="image_profile">
                            <defs>
                                <pattern id="Simg1-46973" patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href={postCreatorInfo.Creater_ProfilePic} width="100" height="100"></image>
                                </pattern>
                            </defs>
                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg1-46973)" className="strokclass">  </polygon>
                        </svg>
                    </div>
                    <div className="col-sm-4 explr-col-3 ">
                        
                    </div>
                    <div className="col col-sm-4 explr-col-3 ExplurgedLevelIconViewProfileBox">

                        {ExplurgedLevelIconViewProfile()}
                        
                    </div>
                </div>
                <div className="row p15Z">                    
                    <div className="col-sm-12 fs16B p0">
                        <span><b>{postCreatorInfo.Creater_Name}</b></span> 
                        &nbsp;{Badge_Img}
                    </div>
                    <div className="col-sm-12 fs14N p0 m5Z">
                        <span>{postCreatorInfo.User_Handle}</span>                        
                    </div>
                    <div className="col-sm-12 fs14N p0">
                        {/* {postCreatorInfo.User_Gender_Text!="" && <span>{postCreatorInfo.User_Gender_Text}, </span>} */}
                        <span>{postCreatorInfo.User_City}, </span>
                        <span>{postCreatorInfo.User_Country}</span>     
                    </div>
                    <div className="col-sm-12 fs14N p0 link-color m5Z">
                        {/* <span>{postCreatorInfo.User_Bio2}</span>
                        <br></br>
                        <span>{postCreatorInfo.User_Bio}</span>                         */}
                        {postCreatorInfo.User_Bio && <ReadMore>{postCreatorInfo.User_Bio}</ReadMore>}   
                    </div>
                </div>
                <div className="row profileButtonsBox mzz10">                    
                    <div className="col profileButtonsBoxInner col-sm-4">
                        <Button variant="primary profileButtons profileButtonsFollow" disabled>+ Follow</Button>
                    </div>
                    <div className="col profileButtonsBoxInner profileButtonsBoxInnerRight col-sm-4">
                        <Button variant="primary profileButtons profileButtonsMessage" disabled>Message</Button>   
                    </div>
                    <div className="col profileButtonsBoxInner profileButtonsBoxInnerRight col-sm-4">
                        <Button variant="primary profileButtons profileButtonsShare" disabled>
                            <img src={profileShare}></img>
                        </Button>   
                    </div>
                </div>
            </>);
 
    }
    const profileSummary = () => {

        var miles_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["miles"].Text_Color:'';        
        var continent_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["continent"].Text_Color:'';
        var country_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["country"].Text_Color:'';

        var city_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["city"].Text_Color:'';
        var place_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["place"].Text_Color:'';
        var kudo_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["kudo"].Text_Color:'';
        var fellow_Text_Color = postCreatorInfo.explurgerSummaryData != undefined ? postCreatorInfo.explurgerSummaryData["fellow"].Text_Color:'';
         
        return (<>
            <div className="row">
                <div className="col col-sm-12">
                    <div className='row'>
                        {/* <div className="col-sm-1 p0 mapProfileMapBox">
                            <img className='Map_Profile_Icon' src={mapProfileMap}></img><br></br>
                            <span>Map</span>
                        </div> */}
                        <div className="col-sm-12 p0 mapProfileSummeryBox">
                            <span>Explurger Summary</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row profile-summary-box" style={{backgroundColor:postCreatorInfo.Cover_BG_Color }}>                    


                <div className="col-sm-12 p0" >
                    <img className='profile-summary-place-jpg' src={profilePlace}></img>
                </div>


                <div className="col-sm-12 p2010 profile-summary-place-img" >

                    <div className="row profile-summary-box-list" >
                        <div className="col p0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={miles_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["miles"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["miles"].Text}                                            
                                </text> 
                            </svg>
                        </div>
                        <div className="col p0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={continent_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["continent"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["continent"].Text}                                            
                                </text> 
                            </svg>                            
                        </div>
                        <div className="col p0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={country_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["country"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["country"].Text}                                            
                                </text> 
                            </svg>
                        </div>
                        <div className="col p0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={city_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["city"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["city"].Text}                                            
                                </text> 
                            </svg>
                        </div>
                    </div>
                    <div className="row profile-summary-box-list profile-summary-box-list-2" >
                        <div className="col p0 profile-hex-box2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={place_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["place"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["place"].Text}                                            
                                </text> 
                            </svg>
                        </div>
                        <div className="col p0 profile-hex-box2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={kudo_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["kudo"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["kudo"].Text}                                            
                                </text> 
                            </svg>                            
                        </div>
                        <div className="col p0 profile-hex-box2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="image_profile">
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={fellow_Text_Color} className="strokclass"></polygon>                               
                                <text className='summary-hex-count' x="50" y="45" textAnchor="middle" fill="black" fontSize="16" fontWeight="bold"> 
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["fellow"].Count} 
                                </text> 
                                <text className='summary-hex-text' x="50" y="70" textAnchor="middle" fill="black" fontSize="13" fontWeight="bold">
                                    {postCreatorInfo.explurgerSummaryData != undefined && postCreatorInfo.explurgerSummaryData["fellow"].Text}                                            
                                </text> 
                            </svg>
                        </div>
                        <div className="col p0"></div>                    
                    </div>
                </div>

            </div>
        </>);

    }    
    

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
    const linkRegexHttpWww = /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g;
    const hasHashRegex = /(?:^|\W)#(\w+)(?!\w)/g;
    const tagHashRegex = /(?:^|\W)@(\w+)(?!\w)/g;
    const regexNewLineCount = /$/mg;

    function urlify(text) {        
        return text.replace(linkRegex, function(url) {

            let withProtocol = url
        
            if(!withProtocol.startsWith("http")) {
              withProtocol = "http://" + url
            }
           
            const newStr = `<a href="${withProtocol}" target="_blank" >${url}</a>`

            //return '<a href="' + url + '" target="_blank">' + url + '</a>';
            return newStr;
        })
    }

    function replaceNLWithBr(txt) {
        return txt.replaceAll(/\n/g, "<br />");
    }

    function hashify(text) {        
        return text.replaceAll(hasHashRegex, function(hash) {
           
            //var newStr = `<a href="javascript:false" style="color:#2d97f7" onclick="${clickCenterModalOpen}" >${hash}</a>`

            var newStr = `<span style="color:#2d97f7">${hash}</span>`;
            return newStr;
        });
    }

    function tagiify(text) {        
        return text.replaceAll(tagHashRegex, function(hash) {
           
            //var newStr = `<a href="javascript:false" style="color:#2d97f7" onclick="${clickCenterModalOpen}" >${hash}</a>`

            var newStr = `<span style="color:#2d97f7">${hash}</span>`;
            return newStr;
        });
    }    


    const ReadMore = ({ children }) => {

        var _Text = children;
        //console.log("ReadMore-", _Text);

        //var post = children;
        if(_Text == undefined) return "";
        var Post_Text = decodeURIComponent(_Text);
        var text_length = 200;
        var mxL = 3;
        var tlns = Post_Text.match(regexNewLineCount).length;
        if(tlns > mxL)
        {
            // calculate length of 2 lines text
            // extract only 3 line
            var textLines = Post_Text.split("\n")
            var text2 = textLines[0] +"\n"+ textLines[1] +"\n"+ textLines[2] +"\n"+ textLines[3];
            var text2Length = text2.length;
            if(text_length > text2Length)
            {
                text_length = text2Length;
            }
        }

        Post_Text = replaceNLWithBr(Post_Text);
        Post_Text = hashify(Post_Text);
        Post_Text = tagiify(Post_Text);
        Post_Text = urlify(Post_Text);        
               
        let text = Post_Text;  
              
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };

        if (text.length > text_length || tlns > mxL) {
            return (
                <p className="text">
                    {isReadMore ? ReactHtmlParser(text.slice(0, text_length)) : ReactHtmlParser(text)}
                    <span
                        onClick={clickCenterModalOpen}
                        className="read-or-hide"
                        style={{ color: "blue" }}
                    >
                        {isReadMore ? "...read more" : " show less"}
                    </span>
                </p>
            );
        } else {
            return (<>{ReactHtmlParser(text)}</>)
        }
    };    

    var ExplurgedLevelIconViewProfile = (data) => {
        //var Explurged_Level = parseInt(data.Total_Explurged_Level);
        // var Explurged_Level = parseInt(10000);
        // if (Explurged_Level < 20000) { // Cobalt
        //     return (<><img className='Level_Icon_Profile' src={cobaltLevelIcon}></img></>)
        // } else if (Explurged_Level < 50000) { // Silver
        //     return (<><img className='Level_Icon_Profile' src={silverLevelIcon}></img></>)
        // } else if (Explurged_Level < 100000) { // GOLD
        //     return (<><img className='Level_Icon_Profile' src={goldLevelIcon}></img></>)
        // } else if (Explurged_Level < 500000) { // Spectra
        //     return (<><img className='Level_Icon_Profile' src={spectraLevelIcon}></img></>)
        // } else { // Prime
        //     return (<><img className='Level_Icon_Profile' src={primeLevelIcon}></img></>)
        // }

        
        //return (<><img className='Level_Icon_Profile' src={postCreatorInfo.Level_Img_Icon}></img></>);

        if (postCreatorInfo.Profile_Type == "1") {
            return (<></>);
        }

        return (<>
                        <div className="row">
                            <div className='col col-sm-12 ExplurgedLevelIconViewProfile'>
                                <img className='Level_Icon_Profile' src={postCreatorInfo.Level_Img_Icon}></img>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className='col col-sm-12 Level_Icon_Profile_Text'>
                                <span>{postCreatorInfo.Level_Name}</span>
                            </div>
                        </div>      
        </>);  
    }
   

    const clickCenterModalOpen = () => {
        CenterModalOpen("block");
    }

    const CenterModalOpen = (display) => {      
        //console.log("CenterModalOpen ", display);
        var modelBox = document.getElementById('dontMissModalBox');
        modelBox.style.display = display;
        var model = document.getElementById('dontMissModal');
        //console.log("CenterModalOpen ", model, display);
        if (model) {
            model.style.display = display;
        }
        if(display == "block")
        {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = "scroll";
        }        
    } 
 

    const profileTop = () => {      
        return (<>            
            <div className='row p0 p0170'>
                <div className='col p0'>
                    <div className={' row block-box p0 block-box-summary'}>

                        <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard2 postCardMP'>
                            <div className='box-1'>
                                {postProfileView()}
                            </div>
                        </div>

                    </div> 
                </div> 
            </div>
        </>);
    } 

    const explurgerSummary = () => {
        
        //console.log("explurgerSummary-", postCreatorInfo, postCreatorInfo.explurgerSummaryData, postCreatorInfo.explurgerSummaryData.length);

        if (!('miles' in postCreatorInfo.explurgerSummaryData))
        {
            return (<></>);
        }    

        return (<>            
            <div className='row p0 p0170'>
                <div className='col p10'>
                    <div className={' row block-box p0 '}>

                        <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard3 p0 m10px00'>
                            <div className={' box-1 '}>
                                {profileSummary()}                                
                            </div>
                        </div>

                    </div> 
                </div> 
            </div>
        </>);
    }

    const postSummary = () => {      
        return (<>            
            <div className='row p0 p0170'>
                <div className='col p0'>
                    <div className={' row block-box p0 block-box-post'}>

                        <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard3 p0 m10px0'>
                            <div className={' box-1 '}>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <div className='row'>                                            
                                            <div className="col col-sm-6 postSummeryBox">
                                                <span>{postCreatorInfo.Post_Count}</span>&nbsp;                                                                                        
                                                <span>{postCreatorInfo.Explurge_Msg}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                                
                            </div>
                        </div>

                    </div> 
                </div> 
            </div>
        </>);
    }    

    const showProfile = () => {
        // postCreatorInfo
        //if (isActive && postData)
        //if (isActive) 
        // status : true
        // if(data.status && data.code == 200)
        if (!isActive && postData && postData.status == true && postData.code == 200)
        {
            return (<>
                {profileTop()}
                {explurgerSummary()}
                {postSummary()}
                {/* <ViewPost attr_id={id} sendDataToParent={handleDataFromChild} resfreshAllowed="0"></ViewPost> */}
                <ViewPost attr_id={id} sendDataToParent={handleDataFromChild} sendDataToParentDontDismissModelHide={handleDataFromChildDontDismissModelHide} dontDismissModelHide={dontDismissModelHide} resfreshAllowed="0"></ViewPost>                
            </>);
        }
        return (<></>);
    }

    const noProfileYet = () => {
        if (!isActive && postData && postData.length==0)
        {
            return (<>
                <div className='no_explurge_yet row p0 p0170'>
                    <div className='col col-sm-12 p0'>No User</div>
                    {/* <div className='col col-sm-12 p0 splashScreen'><img src={splashScreen}></img></div> */}
                </div>
            </>);
        }
        return (<></>);
    }   
    
    const loaderBox = () => {
        if (isActive)
        {
            return (<>
                <div className='loaderbox'>
                    <HashLoader key="mainLoader" className='mainLoader'></HashLoader>
                </div>
                {/* <div className='splashScreen'><img src={splashScreen}></img></div> */}
            </>);
        }
        return (<></>);
    }    

    return (<>
    <div className="container-fluid m0 p0" id="my-container-element">
        <div className="row">
            
            <div className='col-lg-4 col-xl-4 p0'></div>
            <div className='col-lg-4 col-xl-4 p0 max428 m040'>

                    <div className=' p0170 sticky-div'>
                        <TopComponent></TopComponent>
                        {/* <hr className='H-line m0'></hr> */}
                    </div> 
                    <div className=' scroll-div '>
                    
                        <div className='loader text-center p0170'>
                            {isActive && loaderBox()}
                            {/* {isActive && <HashLoader key="mainLoader" className='mainLoader'></HashLoader>} */}
                        </div>

                        {showProfile()}

                        {noProfileYet()}

                    </div>
                
			</div>
            <div className='col-lg-4 col-xl-4 p0'></div>
		</div>
	</div>
   
    <CommonComponent postCreatorInfo={postCreatorInfo} sendDataToParent={handleDataFromChildCommonComponent} ></CommonComponent>
    {/* <Helmet>
        <title>{constants.APP_TITLE + ""}</title>
        <meta property="og:description" content={constants.APP_DESCRIPTION} />
        <meta name="Description" content={constants.APP_DESCRIPTION} />
        <meta name="keywords" content={constants.APP_KEYWORDS} />
    </Helmet>  */}
    </>)
}