import React, {useState, useRef, useEffect} from 'react';

import {isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet} from 'react-device-detect';

// window.onerror = function(message, url, line, col, errorObj) {
//   //console.log(`${message}\n${url}, ${line}:${col}`);
//   //console.log(errorObj);
// };

import * as constants from '../constants';

import logo from '../assets/img/logo-1.png';


export const TopComponent = (props) => {
    const initialized = useRef(false);


    useEffect(() => {
        if (!initialized.current) 
        {
        initialized.current = true; 
        initialized.counter = 1;  

        }
        else
        {
        initialized.counter += 1;
        }
    }, []);

    const getTheExplurgerAppNow = () => {
        //isMobile, isAndroid, isIOS, isWinPhone
        //console.log(isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet);
        var url = constants.APP_WEBSITE_URL;
        if((isMobile || isTablet) && isAndroid)
        {
            url = constants.APP_GOOGLE_PLAY_URL;
        }
        else if((isMobile || isTablet) && isIOS)
        {
            url = constants.APP_APPLE_APP_STORE_URL;
        }                
        window.open(url);
    }   
 
    
    const topView = () => {
        return (<>
            <div className="row justify-content-between">
                <div className="col col-sm-6">
                    <img className='logoImg text-left fll' src={logo}></img>
                </div>
                <div className="col col-sm-6">
                    <button className='btn openAppBtn text-right flr' onClick={getTheExplurgerAppNow}>Open App</button>
                </div>
            </div>
        </>);
    }    


    return (<> 
        <div className='row top-div'>
            <div className='col-sm-12 p0'>
                {topView()}
            </div>
        </div>   
    </>);

}

export default TopComponent;