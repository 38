import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJSExplr = (props) => {
  const initialized = React.useRef(false);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady, displayDimensions} = props;
  const vwidth = 425;
  const vheight = 425;
  const vwidth320 = 307;
  const vwidth425 = 362;
  const vwidth720 = 412;
  const vheight225 = 225;

  let _counter = 0;

  React.useEffect(() => {
    _counter += 1;
    if (!initialized.current) 
    {
      initialized.current = true; 
      initialized.counter = 1; 
    

    }
    else
    {
      initialized.counter += 1;
      //console.error("VideoJSExplr-Already initialized-counter:",initialized.counter);
    }
    
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {

      //console.log('VideoJSExplr-1-', _counter, playerRef.current);

      var sliderContainer = document.getElementsByClassName('slider-container')[0]; 
      var offsetWidth = sliderContainer.offsetWidth;
      var offsetHeight = sliderContainer.offsetHeight;      
      
      //console.log('offsetWidth', offsetWidth, 'offsetHeight', offsetHeight);
      //console.log(displayDimensions, options);
            
      var i,imageViewBox,videoExplrView,profilePic,dataMatch;


      if(offsetWidth < vwidth425)
      {
        var dataMatchs = document.getElementsByClassName('data-match');
        for (i = 0; i < dataMatchs.length; i++) {
          dataMatch = dataMatchs.item(i);
          dataMatch.style.paddingTop = "15px";
        }

        var profilePics = document.getElementsByClassName('footer-left-inner4');
        for (i = 0; i < profilePics.length; i++) {
          profilePic = profilePics.item(i);
          profilePic.style.width = "50%";
        }
        var profilePics = document.getElementsByClassName('footer-left-inner5');
        for (i = 0; i < profilePics.length; i++) {
          profilePic = profilePics.item(i);
          profilePic.style.width = "50%";
        }
      }

      // if(offsetWidth > vwidth)
      // {
      //   offsetWidth = vwidth;
      // }

      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      options.width = displayDimensions.width;
      options.height = displayDimensions.height;

      if(options.explrDt==2)
      {
        //options.height = vheight225;
      }
      
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.id = "explr-vid-"+ options.explrVid;
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        // videojs.log('player is ready');
        // videojs.log('player id-1:'+player.id());
        // videojs.log('player id-2:'+player.id_);
        onReady && onReady(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {

      //console.log('VideoJSExplr-2-', _counter, playerRef.current);

      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (   
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJSExplr;