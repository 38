
const APP_TITLE = "Explurger: A New-Age Social Media App";
const APP_DESCRIPTION = "Explurger, a new gamification driven social media app that rewards users for engagement.";
const APP_KEYWORDS = "Explurger, Social Media App, Social Media App for Travellers, Best Social Media App, Made in India, Travelogue, Bucket List, Artificial Intelligence, Travel App, Facebook like App, Indian Social Media App, Gamification, Atmanirbhar Bharat, Aatma Nirbhar Bharat, Vocal for local";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; 
const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;

const APP_WEBSITE_URL = "https://app.explurger.com";
const APP_GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.explurgerapp";
const APP_APPLE_APP_STORE_URL = "https://apps.apple.com/in/app/explurger/id1470108493";

const userPostList = "userPostList"; // userPostList userProfilePostsList1
const profileSummary = "profileSummary";

//Profile Summery Spread Type send with Post API===========================
const POST_SPREAD_TYPE_EXPLURGER = 0;
const POST_SPREAD_TYPE_MILES = 1;
const POST_SPREAD_TYPE_CONTINENT = 2;
const POST_SPREAD_TYPE_COUNTRY = 3;
const POST_SPREAD_TYPE_CITY = 4;
const POST_SPREAD_TYPE_PLACE = 5;
const POST_SPREAD_TYPE_KUDOS = 6;
const POST_SPREAD_TYPE_FELLOW = 7;
const POST_SPREAD_TYPE_EXPLURGER_LEVEL = 8;

//Post Type================================================
const POST_TYPE_NON_STR = -1;
const POST_TYPE_EXPLURGE_IN_STR = 0;
const POST_TYPE_WITH_IMAGE_STR = 1;
const POST_TYPE_TRAVELLING_STR = 2;
const POST_TYPE_WILL_GO_TO_STR = 3;
const POST_TYPE_MAP_URL_STR = 4;
const POST_TYPE_WITH_SAY_STR = 5;
const POST_TYPE_SPREAD_STR = 6;
const POST_TYPE_PROFILE_PIC_STR = 7;
const POST_TYPE_PROFILE_COVER_STR = 8;
const POST_TYPE_LINK_PREVIEW = 9;
const POST_TYPE_EXPLURGER_LEVEL_STR = 10;
//Media type==================================
// '0','1','2','3'
// 0 for image type and 1 for GIF and 2 for video and 3 for map url
const MEDIA_TYPE_IMAGE = 0; // 0 for image type
const MEDIA_TYPE_GIF = 1; // 1 for GIF 
const MEDIA_TYPE_VIDEO = 2; // 2 for video 
const MEDIA_TYPE_MAP = 3; // 3 for map url

module.exports = {   

    APP_TITLE,
    APP_DESCRIPTION,
    APP_KEYWORDS,
    
    API_BASE_URL,
    BUCKET_URL,
    ADMIN_BASE_URL,

    APP_WEBSITE_URL,
    APP_GOOGLE_PLAY_URL,
    APP_APPLE_APP_STORE_URL,

    userPostList,
    profileSummary,

    POST_SPREAD_TYPE_EXPLURGER,
    POST_SPREAD_TYPE_MILES,
    POST_SPREAD_TYPE_CONTINENT,
    POST_SPREAD_TYPE_COUNTRY,
    POST_SPREAD_TYPE_CITY,
    POST_SPREAD_TYPE_PLACE,
    POST_SPREAD_TYPE_KUDOS,
    POST_SPREAD_TYPE_FELLOW,
    POST_SPREAD_TYPE_EXPLURGER_LEVEL,

    POST_TYPE_NON_STR,
    POST_TYPE_EXPLURGE_IN_STR,
    POST_TYPE_WITH_IMAGE_STR,
    POST_TYPE_TRAVELLING_STR,
    POST_TYPE_WILL_GO_TO_STR,
    POST_TYPE_MAP_URL_STR,
    POST_TYPE_WITH_SAY_STR,
    POST_TYPE_SPREAD_STR,
    POST_TYPE_PROFILE_PIC_STR,
    POST_TYPE_PROFILE_COVER_STR,
    POST_TYPE_LINK_PREVIEW,
    POST_TYPE_EXPLURGER_LEVEL_STR,

    MEDIA_TYPE_IMAGE,
    MEDIA_TYPE_GIF,
    MEDIA_TYPE_VIDEO,
    MEDIA_TYPE_MAP,


}


