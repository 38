import * as React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import MyVideoGoogleSliderMulti from './components/MyVideoGoogleSliderMultiNew';
import ViewPostMain from './component/ViewPostMain';
import ViewProfile from './component/ViewProfile';
import Login from './component/Login';
import Signup from './component/Signup';
// import ViewHealth from './component/ViewHealth';
import ViewWelcome from './component/ViewWelcome';

export default function App() {
  return (
    // {process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE}
    <BrowserRouter>
    <Routes>
      <Route path="/MyVideoGoogleSliderMulti/:id" element={<MyVideoGoogleSliderMulti />} />  
      <Route path="/view-post/:id" element={<ViewPostMain />} />
      <Route path="/profile/:id" element={<ViewProfile />} /> 
      <Route path="/login" element={<Login />} />  
      <Route path="/signup" element={<Signup />} />
      <Route path="/health" element={<ViewWelcome />} />
      <Route path="/" element={<ViewWelcome />} />  
    </Routes>
    </BrowserRouter>
  );
}

