import React, { useState, useEffect, CSSProperties, useRef } from 'react';
//import { BrowserRouter as Router, Link, Route, Routes, useParams } from "react-router-dom";
// import 'react-awesome-slider/dist/styles.css';
//import HashLoader from "react-spinners/ClipLoader";


import '../style.css';
import '../player.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import splashScreen from '../assets/img/sonu-sood.jpeg';

//import * as constants from '../constants';

import TopComponent from './TopComponent';



export default function ViewWelcome() {

    const initialized = useRef(false);    

    useEffect(() => {
        //console.log("width, height-", width, height);
    //}, [width, height]);
    }, []);    
      

    useEffect(() => {

        if (!initialized.current) {
            initialized.current = true; 
        
        }          

    }, []);
    

    const showWelcome = () => {
        
        return (<>
            <h2>Welcome to Explurger</h2>            
        </>);
    } 
    
    const welcomeBox = () => {
        return (<>
            {/* <HashLoader key="mainLoader" className='mainLoader'></HashLoader> */}
            <div className='welcomeBox'>{showWelcome()}</div>
            <div className='splashScreen'><img src={splashScreen}></img></div>            
        </>);
    }    

    return (<>
    <div className="container-fluid m0 p0" id="my-container-element">
        <div className="row">
            
            <div className='col-lg-4 col-xl-4 p0'></div>
            <div className='col-lg-4 col-xl-4 p0 max428 m040'>

                    <div className=' p0170 sticky-div'>
                        <TopComponent></TopComponent>
                        {/* <hr className='H-line m0'></hr> */}
                    </div> 
                    <div className=' scroll-div '>
                    
                        <div className='loader text-center p0170'>
                            {welcomeBox()}
                            {/* {isActive && <HashLoader key="mainLoader" className='mainLoader'></HashLoader>} */}
                        </div>

                        {/* {showWelcome()} */}

                    </div>
                
			</div>
            <div className='col-lg-4 col-xl-4 p0'></div>
		</div>
	</div>

    </>)
}