import React, {useState, useRef, useEffect} from 'react';

// import {isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet} from 'react-device-detect';

// import * as constants from '../constants';

// window.onerror = function(message, url, line, col, errorObj) {
//   //console.log(`${message}\n${url}, ${line}:${col}`);
//   //console.log(errorObj);
// };

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    // static getDerivedStateFromError(error) {
    //   // Update state so the next render will show the fallback UI.
    //   return { hasError: true };
    // }

    static getDerivedStateFromError(error) {
      error['shouldIgnore'] = true;
      error['hasError'] = true;
      return error;
    }    
  
    componentDidCatch(error, info) {
      // Example "componentStack":
      //   in ComponentThatThrows (created by App)
      //   in ErrorBoundary (created by App)
      //   in div (created by App)
      //   in App
      //logErrorToMyService(error, info.componentStack);
      console.log("componentDidCatch-",error, info);
    }
  
    render() {
    //   if (this.state.hasError) {
    //     // You can render any custom fallback UI
    //     return this.props.fallback;
    //   }
  
      return this.props.children;
    }
}

export default ErrorBoundary;