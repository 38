import React, {useState, useRef, useEffect} from 'react';

import {isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet} from 'react-device-detect';

import * as constants from '../constants';

// window.onerror = function(message, url, line, col, errorObj) {
//   //console.log(`${message}\n${url}, ${line}:${col}`);
//   //console.log(errorObj);
// };

export const CommonComponent = (props) => {
    const initialized = useRef(false);

    // const {postData, postId, onSetModalBox2} = props;

    const {postCreatorInfo, sendDataToParent} = props;

    const dontDismissModelHide = useRef(true);
    const timeoutDontDismissModel = 30 * 1000; // in milliseconds (30 Seconds)

    const [dontMissModel, setDontMissModel] = useState(true);    
    const [fullProfileInAppModal, setFullProfileInAppModal] = useState(true);
    const postCountMaxModelTimes = 3;    
    const [fullProfileInAppModalClose, setFullProfileInAppModalClose] = useState(0);

    function handleDataToParent(data) {
        dontDismissModelHide.current = data;
        sendDataToParent(data);
    }

    useEffect(() => {
        if (!initialized.current) 
        {
        initialized.current = true; 
        initialized.counter = 1;  

        }
        else
        {
        initialized.counter += 1;
        }
    }, []);

    const getTheExplurgerAppNow = () => {
        //isMobile, isAndroid, isIOS, isWinPhone
        //console.log(isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet);
        var url = constants.APP_WEBSITE_URL;
        if((isMobile || isTablet) && isAndroid)
        {
            url = constants.APP_GOOGLE_PLAY_URL;
        }
        else if((isMobile || isTablet) && isIOS)
        {
            url = constants.APP_APPLE_APP_STORE_URL;
        }                
        window.open(url);
    }    


    const BottomModalOpen = (display) => {
        //console.log("BottomModalOpen ", display);
        var modelBox = document.getElementById('staticBackdropBox');
        modelBox.style.display = display;
        var model = document.getElementById('staticBackdrop');
        //console.log("BottomModalOpen ", model, display);
        if (model) {
            model.style.display = display;
        }
        if(display == "block")
        {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = "scroll";
        }
    }

    const modalClose = () => {
        var modelBox = document.getElementById('staticBackdropBox');
        modelBox.style.display = 'none';
        var model = document.getElementById('staticBackdrop'); 
        if (model) {
            model.style.display = 'none';

            // bottomModelHide.current = false;
            // setTimeout(()=>{
            //     bottomModelHide.current = true;
            // },timeoutBottomModel);

            setFullProfileInAppModalClose((fullProfileInAppModalClose + 1));

            //console.log('fullProfileInAppModalClose-', fullProfileInAppModalClose, postCountMaxModelTimes);

            if(fullProfileInAppModalClose >= (postCountMaxModelTimes-1))
            {
                setFullProfileInAppModal(false);
            }            
        }
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = "scroll";
    }

    const dontDismissModalClose = () => {
        var modelBox = document.getElementById('dontMissModalBox');
        modelBox.style.display = 'none';
        var model = document.getElementById('dontMissModal');
        if (model) {
            model.style.display = 'none';

            //dontDismissModelHide.current = false;
            handleDataToParent(false);

            setTimeout(()=>{
                //dontDismissModelHide.current = true;
                handleDataToParent(true);
            },timeoutDontDismissModel);
        }
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = "scroll";
    }    

    const clickCenterModalOpen = () => {
        CenterModalOpen("block");
    }

    const CenterModalOpen = (display) => {      
        //console.log("CenterModalOpen ", display);
        var modelBox = document.getElementById('dontMissModalBox');
        modelBox.style.display = display;
        var model = document.getElementById('dontMissModal');
        //console.log("CenterModalOpen ", model, display);
        if (model) {
            model.style.display = display;
        }
        if(display == "block")
        {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = "scroll";
        }        
    }     
    
    const seeTheFullProfileInAppModal = () => {
        return (<>
            <div id="staticBackdropBox" style={{display: 'none'}} >
                <div className="fade modal-backdrop show"></div>
                <div className="modal staticBackdrop fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog seeTheFullProfileInAppModalDialog p0170">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <div className="row">

                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="col-sm-12 img_data_modal modalUserProfile">
                                                <svg viewBox="0 0 100 100" className="image_profile">
                                                    <defs>
                                                        <pattern id="Simg100" patternUnits="userSpaceOnUse" width="100" height="100">
                                                            <image href={postCreatorInfo.Creater_ProfilePic} width="100" height="100">
                                                            </image>
                                                        </pattern>
                                                    </defs>
                                                    <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg100)" className="strokclass">  </polygon>
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <p className='bottom-modelTitle'><b>See full profile in the app</b></p>
                                            <p className='bottom-modelDesc'>Enjoy photos, videos & more from <b>{postCreatorInfo.Creater_Name}</b></p>
                                            <button className='btn getTheApp' onClick={getTheExplurgerAppNow}>Get the Explurger app now</button>
                                            <br></br>
                                            <br></br>
                                            {fullProfileInAppModal && <div><a className='notNow' onClick={modalClose}>Not Now</a><br></br><br></br></div>}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    const dontMissModal = () => {
        return (<>
            <div id="dontMissModalBox" style={{display: 'none'}} >
                <div className="fade modal-backdrop show"></div>
                <div className="modal dontMissModal fade" id="dontMissModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered dontMissModalDialog p0170">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <button type="button" className="btn-close" aria-label="Close" onClick={dontDismissModalClose}></button> */}
                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={dontDismissModalClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                            </div>
                            <div className="modal-body text-center">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="col-sm-12 img_data_modal modalUserProfileCenter">
                                        <svg viewBox="0 0 100 100" className="image_profile">
                                            <defs>
                                                <pattern id="Simg101" patternUnits="userSpaceOnUse" width="100" height="100">
                                                    <image href={postCreatorInfo.Creater_ProfilePic} width="100" height="100">
                                                    </image>
                                                </pattern>
                                            </defs>
                                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#Simg101)" className="strokclass">  </polygon>
                                        </svg>
                                    </div>
                                    </div>                            
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <p className='modelTitle'><b>Don’t Miss</b></p>
                                        <p className='modelDesc'> Photos, videos and ways to connect with <br></br><b>{postCreatorInfo.Creater_Name}</b></p>
                                        <button className='btn getTheAppCenetr' onClick={getTheExplurgerAppNow}>Get the Explurger app now</button>
                                        <br></br>
                                        <br></br>
                                        {dontMissModel && <a className='notNow' onClick={dontDismissModalClose}>Not Now</a>}
                                        <br></br>
                                        <br></br>
                                    </div>                            
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </>)
    }     


    return (<> 
        {dontMissModal()}
        {seeTheFullProfileInAppModal()}    
    </>);

}

export default CommonComponent;