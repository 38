import React, {useState, useRef, useEffect} from 'react';

import videojs from 'video.js';
import VideoJSExplr from './VideoJSExplr';

// window.onerror = function(message, url, line, col, errorObj) {
//   console.error("on window.onerror");
//   console.log(`${message}\n${url}, ${line}:${col}`);
//   console.log(errorObj);
// };

export const CropImage = (props) => {
    const initialized = useRef(false);
    const cwidth = 425;
    const cheight = 425;
    const {postData, imageData, postId, displayMode, displayIndex, slideIndex, displayDimensions} = props;  

    const canvasRef = useRef(null);
    const imgRef = useRef(null);    
    
    const [hasface, setHasFace] = useState(false);

    const [output, setOutput] = useState(null);
    const [imgShow, setImgShow] = useState(true);
    const [imgShowCanvas, setImgShowCanvas] = useState(false);
    
    const [jimpImage, setJimpImage] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [transformedImage, setTransformedImage] = useState(undefined);  

    
    const [videoPlayButton, setVideoPlayButton] = useState(true);
    const [videoPlayButtonShow, setVideoPlayButtonShow] = useState(true);
    
    const [videoBox, setVideoBox] = useState(false);

    const playerRef = useRef(null);
    
    const faceCornerbyTheFaceCenterPointY = (centerY, mediaHeight) => {      
      if (centerY <= (mediaHeight/3)){
          return 'top';

      }else if (centerY <= (mediaHeight*2)/3){
          return 'center';
      }else{
          return 'bottom';
      }
    }
  
    const faceCornerbyTheFaceCenterPointX = (centerX, mediaWidth) => {      
      if (centerX <= (mediaWidth/3)){
          return 'left';

      }else if (centerX <= (mediaWidth*2)/3){
          return 'center';
      }else{
          return 'right';
      }
    }    

    function imageToCanvasDataURL2(imageData, image)
    {
      let centerX = parseFloat(imageData.Face_Info.centerX);
      let centerY = parseFloat(imageData.Face_Info.centerY);
      let mediaWidth = image.naturalWidth;
      let mediaHeight = image.naturalHeight;
      
      var cropRect;

      if(mediaWidth < mediaHeight)
      {      
        let faceCornerY = faceCornerbyTheFaceCenterPointY(centerY, mediaHeight);
        //console.log('faceCornerY',faceCornerY);
        if(faceCornerY == "top")
        {
          cropRect = {
            'x': 0,
            'y': 0,
            'width': mediaWidth,
            'height': mediaWidth
          }      
        } 
        else if(faceCornerY == "bottom")
        {
          cropRect = {
            'x': 0,
            'y': mediaHeight - mediaWidth,
            'width': mediaWidth,
            'height': mediaWidth
          }        
        }
        else if(faceCornerY == "center")
        {
          //console.log((mediaHeight/2)+'-('+(mediaWidth/2)+'+'+((mediaHeight/2)-centerY)+')');
          var newY = (mediaHeight/2) - ((mediaWidth/2)+((mediaHeight/2)-centerY));
          //console.log('newY',newY);
          if (newY < 0)
              newY = 0;
          cropRect = {
            'x': 0,
            'y': newY,
            'width': mediaWidth,
            'height': mediaWidth
          }         
        }
      }
      else
      {
        let faceCornerX = faceCornerbyTheFaceCenterPointX(centerX, mediaWidth);
        //console.log('faceCornerX',faceCornerX);
        if(faceCornerX == "left")
        {
          cropRect = {
            'x': 0,
            'y': 0,
            'width': mediaHeight,
            'height': mediaHeight
          }      
        } 
        else if(faceCornerX == "right")
        {
          cropRect = {
            'x': mediaWidth-mediaHeight,
            'y': 0,
            'width': mediaHeight,
            'height': mediaHeight
          }        
        }
        else if(faceCornerX == "center")
        {
          //console.log((mediaWidth/2)+'-('+(mediaHeight/2)+'+'+((mediaWidth/2)-centerX)+')');
          var newX = (mediaWidth/2) - ((mediaHeight/2)+((mediaWidth/2)-centerX));
          //console.log('newX',newX);
          if (newX < 0)
            newX = 0;
          cropRect = {
            'x': newX,
            'y': 0,
            'width': mediaHeight,
            'height': mediaHeight
          }         
        }        
      }

      //console.log("imageToCanvasDataURL2-cropRect-",cropRect);
      //*/  
      
      return cropRect;
    }

    function imageToCanvasDataURL(imageData, image, callback) {

      //console.log("imageToCanvasDataURL--------------------------44444444444444444444444444444444444444");

      var canvasImage = canvasRef.current;  

      var crop = calculateWidthHeight(imageData, image);
      //console.log("crop-",crop, image.src);
      if(crop == null)
      {
        //callback(); 
        return;
      }

      //const canvas = document.createElement('canvas');
      const canvas = canvasImage;

      canvas.width = displayDimensions.width;
      canvas.height = displayDimensions.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
          throw new Error('No 2d context');
      }         

      const pixelRatio = window.devicePixelRatio;

      ctx.imageSmoothingQuality = 'high';
      // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
      try {
        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,           
          0,
          0,
          displayDimensions.width,
          displayDimensions.height 
        );
      } catch (err) {
        console.error(`drawImage-Error: ${err}`);
      }        

      try {
        callback();            
      } catch (err) {
        console.error(`dataURL-Error: ${err}`);
      }
    }     

    const getTopPoint = (height, width, faceCenterY) => {
      //console.log('getTopPoint-', height, width, faceCenterY);
      if (faceCenterY <= height / 2) { // Face is near the top edge
          return 0;
      // } else if ((scaledHeight - faceCenterY) <= height / 2) { // face is near bottom edge
      //     return height - scaledHeight;
      }else if(faceCenterY >= (height /3 ) * 2 ){
        return (height - width);      
      } else {
          return (height / 2) - faceCenterY;
      }
      // var newY = (mediaHeight/2) - ((mediaWidth/2)+((mediaHeight/2)-centerY));
      // var newY =(mediaWidth/2)-centerY;
    }

    const getLeftPoint = (width, height, faceCenterX) => {
      //console.log('getLeftPoint-', width, height, faceCenterX);
      if (faceCenterX <= width / 2) { // face is near the left edge.
        return 0;
      // } else if ((scaledWidth - faceCenterX) <= width / 2) {  // face is near right edge
      //     return (width - scaledWidth);
      }else if(faceCenterX >= (width /3 ) * 2 ){
        return (width - height);      
      } else {
        return ((width / 2) - faceCenterX);
      }

      // var newX = (mediaWidth/2) - ((mediaHeight/2)+((mediaWidth/2)-centerX));
      // var newX = (mediaHeight/2)-centerX;

      //return (width / 2) - faceCenterX;
    }
      
    const faceRectCalcPortrait  = (imageData, image) => {
      //console.log("faceRectCalcPortrait------------------------------------------------------------------------------");
      let centerX = parseFloat(imageData.Face_Info.centerX);
      let centerY = parseFloat(imageData.Face_Info.centerY);
      let width = image.width;
      let height = image.height;
      let naturalWidth = image.naturalWidth;
      let naturalHeight = image.naturalHeight;

      let scaleX = width / naturalWidth;
      let scaleY = height / naturalHeight;

      try
      {
        if (scaleX != scaleY) 
        {
          //console.log('scaleX-',scaleX,'scaleY-',scaleY);

          let left = 0;
          let top = 0;
          
          //if (scaleX < scaleY) {
          if (naturalWidth > naturalHeight) {
            left = getLeftPoint(naturalWidth, naturalHeight, centerX);     
            //console.log('left-',left);
          } else {
            top = getTopPoint(naturalHeight, naturalWidth, centerY);  
            //console.log('top-',top);
          }

          if(left < 0);
            left = 0;
          if(top < 0);
            top = 0; 

          let minWidthHeight = Math.min(naturalWidth, naturalHeight);
          var cropRect = {
            'x': left,
            'y': top,
            'width': minWidthHeight,
            'height': minWidthHeight
          }   
          //console.log('cropRect',cropRect);  
          
          var cropRectCenter = imageToCanvasDataURL2(imageData, image);
          //console.log('cropRectCenter',cropRectCenter);  
          if(cropRectCenter != null)
            cropRect = cropRectCenter;
          //console.log('cropRect',cropRect);  
          return cropRect;        
        }
        else
          return null;
      }
      catch(err)
      {
        console.error('faceRectCalcPortrait-err-',err);
      }
      
    }   

    const calculateWidthHeight = (imageData, image) => {
      //console.log('calculateWidthHeight--------------------------------------------------------------------------------------------');

      let scaleMedia = image.naturalWidth / image.naturalHeight;
      let scaleDevice = image.width / image.height;   
      
      //console.log('scaleMedia-',scaleMedia,'scaleDevice-',scaleDevice);

      let facesCount = imageData.Face_Info.facesCount;
      let faces = imageData.Face_Info.faces;
      let centerX = parseFloat(imageData.Face_Info.centerX);
      let centerY = parseFloat(imageData.Face_Info.centerY);

      var mediaWidth = image.naturalWidth;
      var mediaHeight = image.naturalHeight;      

      const postMediaCount = displayDimensions.postMediaCount;
      
      const mediaType = imageData.Media_Type;      
      const spreadType = postData.Spread_Type;
      const postType = postData.Post_Type;
      // Media_Type : "0"
      // Post_Type : "6"
      // Spread_Type : "0"

      var noCrop = 0;
      if(postMediaCount > 1 && postType == "6" && mediaType == "0" && spreadType == "0" && displayIndex == 0)
      {
        noCrop = 1;
      }

      // console.log('postMediaCount-', postMediaCount, 'displayIndex-', displayIndex
      //   , 'postType-', postType
      //   , 'mediaType-', mediaType
      //   , 'spreadType-', spreadType
      //   , 'noCrop-', noCrop
      // );
      
      // console.log("'"+imageData.Wall_Media_Path.toString()+"',");
      // console.log('facesCount-',facesCount,'faces-',faces);
      // console.log('centerX-',centerX,'centerY-',centerY, 'Media_Width-',mediaWidth, 'Media_Height-',mediaHeight);

      // console.log('image.naturalWidth-',image.naturalWidth,'image.naturalHeight-',image.naturalHeight, 'image.width-',image.width, 'image.height-',image.height);
      
      // console.log('imageData.GCS_Video_Placeholder',imageData.GCS_Video_Placeholder);
      // console.log(imageData.Media_FilePath, imageData.Wall_Media_Path);   

      var cropRect = null;
      var cropWidth = null;
      var faceRect = null;
      // var cropRect = {
      //   'x': 0,
      //   'y': 0,
      //   'width': mediaWidth,
      //   'height': mediaHeight
      // };
      
      if(scaleDevice == 1 && noCrop == 0)
      {
        cropWidth = Math.min(mediaWidth, mediaHeight);
        cropRect = {
          'x': (mediaWidth - cropWidth) / 2.0,
          'y': (mediaHeight - cropWidth) / 2.0,
          'width': cropWidth,
          'height': cropWidth
        };
      }

      // case 1. check landscape without face 
      if(
          scaleMedia != 1 // need to scale as media is not 1:1
          && scaleDevice != 1 // lanscape rectangle
          && facesCount != undefined && (facesCount == "0" || facesCount == 0) // no face
      )
      {
        return cropRect;
      }
      // case 2. check 1:1 without face
      else if(
        scaleMedia != 1 // need to scale as media is not 1:1
        && scaleDevice == 1 // 1:1 square
        && facesCount != undefined && (facesCount == "0" || facesCount == 0) // no face
      )
      {

        return cropRect;
      }
      // case 3. check landscape with face
      else if(
        scaleMedia != 1 // need to scale as media is not 1:1
        && scaleDevice != 1 // lanscape rectangle
        && facesCount == undefined // means face exist with face
      )
      {
        // faceRect = faceRectCalc(imageData, image);
        // if( faceRect != null)
        // {
        //   cropRect = faceRect;
        // }
        //faceRect = null; // no crop
        return cropRect;
      }
      // case 4. check 1:1 with face
      else if(
        scaleMedia != 1 // need to scale as media is not 1:1
        && scaleDevice == 1  // 1:1 square
        && facesCount == undefined // means face exist with face
      )
      {        
        // scaleMedia- 0.6669758812615956
        if(scaleMedia < 1) // portrait 
        {
          faceRect = faceRectCalcPortrait(imageData, image);    
          //console.log("---------------------faceRect----------------------",faceRect);      
          if( faceRect != null)
          {
            cropRect = faceRect;
          }
        }
        else
        {
          // no need on landscape
        }
        //faceRect = null; // no crop
        return cropRect;
      }       
  
      return null;
    };

    const transformImage = (imageData, image) => {
      
    };

    const getImageType = (imageData) => {
      var imgtype = 'image/jpeg';
      var Media_FilePath = imageData.Media_FilePath;
      // Media_FilePath
      //   : 
      //     "posts/wall/171949141525646671.jpg"
      var img_type = Media_FilePath.split('.').pop().toLowerCase();
      switch(img_type) {
        case 'jpg':
          imgtype = 'image/jpg';
        case 'jpeg':
          // code block
          imgtype = 'image/jpeg';
          break;
        case 'png':
          // code block
          imgtype = 'image/png';
          break;
        case 'gif':
          // code block
          imgtype = 'image/gif';
          break;          
        default:
          // code block
          imgtype = 'image/jpg';
      }
      return imgtype;
    };

    const onLoadImage = () => {
      //console.log('onLoadImage');
      cropImageNow(imageData);
    };
    
    const onLoadImageCanvas = () => {
      //console.log('onLoadImageCanvas'); 
    };

    const onErrorImage = () => {
      console.error('onErrorImage');
    };
    
    const onErrorImageCanvas = () => {
      console.error('onErrorImage'); 
    };    

    const cropImageNow = (imageData) => {

      var image = imgRef.current;
      
      imageToCanvasDataURL(imageData, image, ()=>{
        setImgShow(false);
        setImgShowCanvas(true);
      });

    };   

  useEffect(() => {

    if (!initialized.current) 
    {
      initialized.current = true; 
      initialized.counter = 1;     
    
      if(imageData.Media_Type=="2" && displayMode==2)
      {
        setVideoBox(true);
      }
      else
      {
        var image = imgRef.current;
        // Wall_Media_Path
        // GCS_Video_Placeholder
        if(imageData.Media_Type=="2")
          image.src = imageData.GCS_Video_Placeholder;      
        else
          image.src = imageData.Wall_Media_Path;
      }
    }
    else
    {
      initialized.counter += 1;
    }
  }, []);

  const onPlayuerStart = () => {
    //console.log('onPlayuerStart');
    if(displayMode == 2)
    {
      setImgShow(false);
      setImgShowCanvas(false);  
      setVideoPlayButton(false); 
      setVideoPlayButtonShow(false);     
      setVideoBox(true); 
    }      
  };

  const handlePlayerReadyCrop = (player) => {

    //console.log('handlePlayerReadyCrop - handlePlayerReady--------------------------------');

    playerRef.current = player;
    // You can handle player events here, for example:
    player.on('waiting', () => {
        console.log('player is waiting - handlePlayerReady');
    });

    player.on('dispose', () => {
        console.log('player will dispose - handlePlayerReady');
    });
  
    player.on('touchstart', function (e) {
        try {
            //console.log('CropImage-player is touchstart - handlePlayerReady', e.target.nodeName);
            var bigPlayButton = e.target.closest(".vjs-big-play-button");
            var videoJSBox = e.target.closest(".vjs-big-play-centered.video-js");
            var videoId = videoJSBox.id;
            //if (bigPlayButton !== null) {
            if (e.target.nodeName === 'VIDEO' || bigPlayButton !== null) {                
                //videoJSBox.focus();
                //videoJSBox.blur();
                var cPlayer = videojs(videoId); 
                //console.log(cPlayer.paused(), cPlayer);
                if (cPlayer.paused()) { 
                    //cPlayer.play();
                    var promisePlay = cPlayer.player_.play();
                    //console.log("promisePlay",promisePlay);
                    if (promisePlay !== undefined) {
                        Promise.resolve(promisePlay).then(_s => {
                            //console.log('promisePlay success');
                            // Autoplay started!
                            //console.log(_s);
                        }).catch(error => {
                            // Autoplay was prevented.
                            // Show a "Play" button so that user can start playback.
                            console.log('promisePlay failed');
                            console.log(error);
                        });
                    }
                } else {   
                  try {
                    //cPlayer.pause();
                    var promisePlayPause = cPlayer.player_.pause();
                    //console.log("promisePlayPause-1-",promisePlayPause);
                    if (promisePlayPause !== undefined) {
                        Promise.resolve(promisePlayPause).then(_s => {
                            //console.log('promisePlayPause success');
                            // Autoplay started!
                            //console.log(_s);
                        }).catch(error => {
                            // Autoplay was prevented.
                            // Show a "Play" button so that user can start playback.
                            console.log('promisePlayPause failed-1');
                            console.log(error);
                        });
                    }
                  }
                  catch(err) {
                      console.error("promisePlayPause-1-",err);
                  }
                    setTimeout(function(cPlayer) { 
                      
                      try {
                        //cPlayer.pause();
                        var promisePlayPause = cPlayer.player_.pause();
                        //console.log("setTimeout-promisePlayPause-2-",promisePlayPause);
                        if (promisePlayPause !== undefined) {
                            Promise.resolve(promisePlayPause).then(_s => {
                                //console.log('setTimeout-promisePlayPause success');
                                // Autoplay started!
                                //console.log(_s);
                            }).catch(error => {
                                // Autoplay was prevented.
                                // Show a "Play" button so that user can start playback.
                                console.log('setTimeout-promisePlayPause failed-2');
                                console.log(error);
                            });
                        }
                      }
                      catch(err) {
                        console.error("setTimeout-promisePlayPause-2-",err);
                      }
                    }, 300, cPlayer);
                }
            }
        }
        catch(err) {
            console.error("touchstart-",err);
        }
    });
    
    player.player_.bigPlayButton.on('click', function (e) {
      try {
          var that = this;
          //console.log('CropImage-player is click - bigPlayButton', e.currentTarget.nodeName);
          var videoJSBox = e.target.closest(".vjs-big-play-centered.video-js");

          //console.log("vjs-playing-",videoJSBox.classList.contains("vjs-playing"));
          if(videoJSBox.classList.contains("vjs-playing"))
          {
            var cPlayer = that.player_;
            //console.log("cPlayer-1-",cPlayer);
            //var promisePlayPause = cPlayer.player_.pause();
            //console.log("promisePlayPause-1-",promisePlayPause);

            try {
              var promisePlayPause = cPlayer.player_.pause();
              //console.log("promisePlayPause-1-",promisePlayPause);
              if (promisePlayPause !== undefined) {
                  Promise.resolve(promisePlayPause).then(_s => {
                      //console.log('promisePlayPause success');
                      // Autoplay started!
                      //console.log(_s);
                  }).catch(error => {
                      // Autoplay was prevented.
                      // Show a "Play" button so that user can start playback.
                      console.log('promisePlayPause failed-1');
                      console.log(error);
                  });
              }
            }
            catch(err) {
                console.error("promisePlayPause-1-",err);
            }
              setTimeout(function(cPlayer) {                 
                try {
                  var promisePlayPause = cPlayer.player_.pause();
                  //console.log("setTimeout-promisePlayPause-2-",promisePlayPause);
                  if (promisePlayPause !== undefined) {
                      Promise.resolve(promisePlayPause).then(_s => {
                          //console.log('setTimeout-promisePlayPause success');
                          // Autoplay started!
                          //console.log(_s);
                      }).catch(error => {
                          // Autoplay was prevented.
                          // Show a "Play" button so that user can start playback.
                          console.log('setTimeout-promisePlayPause failed-2');
                          console.log(error);
                      });
                  }
                }
                catch(err) {
                  console.error("setTimeout-promisePlayPause-2-",err);
                }
              }, 300, cPlayer);
          }
      }
      catch(err) {
          console.error("click-",err);
      }
  });

  };     

  if(imageData.Media_Type=="2")
  {

    var autoPlay = false;

    if(slideIndex != undefined && slideIndex == displayIndex)
    {
      autoPlay = true;
    }

    //console.log("slideIndex-",slideIndex, autoPlay);


    let videoJsOptions = {
      explrVid: "pl-"+postId+"-"+displayIndex,
      explrDt:displayMode,
      autoplay: autoPlay,
      controls: true,
      responsive: true,
      fluid: false,
      enableSmoothSeeking: true,
      height:parseInt(displayDimensions.height),
      width:parseInt(displayDimensions.width),
      //poster:imageData.GCS_Video_Placeholder,
      sources: [{
        src: imageData.Wall_Media_Path,
        type: 'video/mp4'
      }],
      //nativeControlsForTouch: true,
      controlBar:{
          playToggle:true,
          durationDisplay:false,
          remainingTimeDisplay:true,
          fullscreenToggle: false,
          pictureInPictureToggle:false,
          timeDivider: false,
      },                               
    };    
    // video play box on top of image
    if(displayMode == 2)
    {
      return (<>
        { videoBox && <VideoJSExplr key={'videoView-'+postId+'-'+displayIndex} options={videoJsOptions} onReady={handlePlayerReadyCrop} displayDimensions={displayDimensions} ></VideoJSExplr>}
      </>);
    }
    else
    {    
      return (<>
        {<img ref={imgRef} className='imageView'  style={{ display: imgShow ? "block" : "none" }} onLoad={onLoadImage} onError={onErrorImage} onClick={onPlayuerStart} />}    
        {<canvas ref={canvasRef} className='imageView'  style={{ display: imgShowCanvas ? "block" : "none" }} />} 
        {<div className='startPlayIcon' onClick={onPlayuerStart} style={{ display: videoPlayButtonShow ? "block" : "none" }}></div>}
        
      </>);  
    }
  }
  else if(imageData.Media_Type=="0")
  {
    return (<>
      {<img ref={imgRef} className='imageView'  style={{ display: imgShow ? "block" : "none" }} onLoad={onLoadImage} onError={onErrorImage} />}    
      {<canvas ref={canvasRef} className='imageView' style={{ display: imgShowCanvas ? "block" : "none" }} />} 
    </>);  
  }
  else
  {
    return (<></>);
  }

}

export default CropImage;