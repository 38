import React, {useState, useRef, useEffect} from 'react';

export const CropProfileImage = (props) => {
    const initialized = useRef(false);

    const {postData, postMedia, postId, displayDimensions} = props;

    const canvasRef = useRef(null);
    const canvasSmallRef = useRef(null);
    const imgRef = useRef(null);    
    
    const [imgShow, setImgShow] = useState(false);
    const [imgShowCanvas, setImgShowCanvas] = useState(false); 

    const canvasSmallWidth = 5;
    const canvasSmallHeight = 5;

    function imageToCanvasDataURL(image, callback) {

        //console.log("CropProfileImage -imageToCanvasDataURL--------------------------555555555555555555555555555555555");

        // Dynamically create a canvas element
        // canvasSmallRef
        //var canvasSmall = document.createElement("canvas");
        var canvasSmall = canvasSmallRef.current; 

        var ctxSmall = canvasSmall.getContext("2d");
        
        canvasSmall.width = canvasSmallWidth;
        canvasSmall.height = canvasSmallHeight;

        try {
          ctxSmall.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvasSmallWidth, canvasSmallHeight);
        } catch (err) {
            console.error(`drawImage-Error: ${err}`);
        }

        var canvasImage = canvasRef.current; 

        const canvas = canvasImage;

        canvas.width = displayDimensions.width;
        canvas.height = displayDimensions.height;

        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }         

        ctx.imageSmoothingQuality = 'high';
        try {
            ctx.drawImage(canvasSmall, 0, 0, canvasSmallWidth, canvasSmallHeight, 0, 0, displayDimensions.width, displayDimensions.height);
        } catch (err) {
            console.error(`drawImage-Error: ${err}`);
        }        

        try {
            callback();            
        } catch (err) {
            console.error(`dataURL-Error: ${err}`);
        }
    } 


    const onLoadImage = () => {
      //console.log('onLoadImage');
      cropImageNow();
    };
    
    const onLoadImageCanvas = () => {
      //console.log('onLoadImageCanvas'); 
    };

    const onErrorImage = () => {
      console.error('onErrorImage');

      var image = imgRef.current;
      //console.log(image, image.closest(".Place_Photo_Box"), image.closest(".Place_Photo_Box").closest(".flex-parent"));

    //   image.closest(".Place_Photo_Box").style.width = 0;
    //   // Map_Photo_Box
    //   image.closest(".Place_Photo_Box").closest(".flex-parent").querySelector(".Map_Photo_Box").style.width = "100%";

    //   image.remove();
    };
    
    const onErrorImageCanvas = () => {
      console.error('onErrorImage'); 
    };    

    const cropImageNow = () => {

      var image = imgRef.current;
       
      imageToCanvasDataURL(image, ()=>{
        setImgShow(false);
        setImgShowCanvas(true);
      });

    };
    

  useEffect(() => {

    if (!initialized.current) 
    {
        initialized.current = true; 
        initialized.counter = 1;   
        
        var image = imgRef.current;

        var profileURL = null;
        postMedia.map((val, i) => {
            profileURL = val.Wall_Media_Path
        });

        image.src = profileURL;
    }
    else
    {
      initialized.counter += 1;
    }
    //console.log(imgRef.current);
    //console.error("CropImage-Already initialized-counter:",initialized.counter);
  }, []);
    

  return (<>    
    {<img id={"profile-image-21-"+postId} ref={imgRef} style={{padding: 0, display: imgShow ? "block" : "none", backgroundColor: postMedia[0].Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height }} onLoad={onLoadImage} onError={onErrorImage} />}    
    {<canvas id={"profile-image-22-"+postId} ref={canvasSmallRef} style={{padding: 0, display: "none", width:canvasSmallWidth, height:canvasSmallHeight }}  />} 
    {<canvas id={"profile-image-23-"+postId} ref={canvasRef} style={{padding: 0, display: imgShowCanvas ? "block" : "none", width:displayDimensions.width, height:displayDimensions.height }}  />}
  </>);

}

export default CropProfileImage;