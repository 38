import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from "react-router-dom";

//import PullToRefresh from 'react-simple-pull-to-refresh';

import '../style.css';
import '../player.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import ViewPost from './ViewPost';
import CommonComponent from './CommonComponent';
import TopComponent from './TopComponent';
import ErrorBoundary from './ErrorBoundary';

// Post_Type  Spread_Type	Post Name        Post_ID
//     0	      0	        Explurged In      44824 / 44827- with media
//     1	      0	        Pictures/Videos   44117/44115
//     2	      0	        Travelling to     
//     3	      0	        Will go to        44110
//     4	      0	        along with        44121/44122
//     5	      0	        Says              44120/44105/44119
//     6	      0	        journey so far    44097/44096/44094/44092/44091
//     7	      0	        Profile Picture   44103
//     8	      0	        Cover Picture     44104
//     9	      0	        Link Preview      44126/44127/44125

// 44210 spreded

// Nigam Kr - 22a9a9b6e3ea868aa64b3e539d4f440e
// Beauty - k76he9z4sjas58qq3c5l3jqgmpw1653371096324


export default function ViewPostMain(props) {

    const { id } = useParams();
    const [postCreatorInfo, setPostCreatorInfo] = useState([]);

    const {attr_id} = props; 

    const [userId, setUserId] = useState(id);

    const dontDismissModelHide = useRef(true);

    function handleDataFromChild(data) {
        setPostCreatorInfo(data);
    }

    function handleDataFromChildCommonComponent(data) {
        dontDismissModelHide.current = data;
    }    

    function handleDataFromChildDontDismissModelHide(data) {        
        dontDismissModelHide.current = data;
    }

    const initialized = useRef(false);

    useEffect(() => {
        //console.log("width, height-", width, height);
        if(attr_id != undefined && attr_id != null)
            setUserId(attr_id);
    //}, [width, height]);
    }, []);    


    useEffect(() => {

        if (!initialized.current) {
            initialized.current = true; 
        
        }

    }, []);

    return (<>
    <div className="container-fluid m0 p0" id="my-container-element">
        <div className="row">
            
            <div className='col-lg-4 col-xl-4 p0'></div>
            <div className='col-lg-4 col-xl-4 p0 max428 m040'>

                {/* <PullToRefresh onRefresh={handleRefresh}> */}

                    <div className=' p0170 sticky-div'>
                        <TopComponent></TopComponent>
                        {/* <hr className='H-line m0'></hr> */}
                    </div> 

                    <div className=' scroll-div '>
                        <ErrorBoundary fallback={<p>Something went wrong</p>}>
                            <ViewPost attr_id={id} sendDataToParent={handleDataFromChild} sendDataToParentDontDismissModelHide={handleDataFromChildDontDismissModelHide} dontDismissModelHide={dontDismissModelHide} resfreshAllowed="1"></ViewPost>
                        </ErrorBoundary>
                    </div>
                {/* </PullToRefresh> */}

			</div>
            <div className='col-lg-4 col-xl-4 p0'></div>
		</div>
	</div>

    <CommonComponent postCreatorInfo={postCreatorInfo} sendDataToParent={handleDataFromChildCommonComponent} ></CommonComponent>
    </>)
}