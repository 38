import React, { useState, useEffect, CSSProperties } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from "react-router-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import * as constants from '../constants';

var logo = constants.ADMIN_BASE_URL + 'images/logo.png';

export default function Login() {


    const signupSubmit = (event) => {

    }

    return (<><br></br>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-7'></div>
                <div className='col-sm-5'>
                    <div className='card'>
                        <div className='card-body'>
                            <form onSubmit={signupSubmit}>
                                <div className='text-center'>
                                    <img className='milesImg' src={logo}></img>
                                    <h2 className=''>Signup</h2><hr></hr>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                    <input class="form-control" id="exampleFormControlInput1" name='name' placeholder="name"></input>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Email/Mobile</label>
                                    <input class="form-control" id="exampleFormControlInput1" name='emailMobile' placeholder="name@example.com"></input>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="exampleFormControlInput1" name='password' placeholder="Password"></input>
                                </div>
                                <div class="d-grid gap-2">
                                    <button className='btn btn-primary btn-block' type='submit'>Signup</button>
                                </div>
                                <p className="small fw-bold mt-2 pt-1 mb-2">Do you have an account? <Link to={"/login"} className="link-danger">Login</Link></p>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>)
}